@charset "UTF-8";
/* line 201, ../scss/style.scss */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
/* line 205, ../scss/style.scss */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

.cart_totals h2 {
  font-size: 12px;
  font-weight: bold;
  display: none; }

tr.cart_item {
  border-bottom: 1px solid #111111 !important;
  border-top: 1px solid #111111 !important; }

tr.shipping.alt-table-row {
  border-bottom: 1px solid #111111 !important; }

.cart_totals, .shipping_calculator {
  font-size: 12px; }

.shipping_calculator a {
  font-weight: bold;
  font-size: 12px; }

tr.cart-subtotal th,
tr.cart-subtotal td,
tr.shipping th,
tr.shipping td,
tr.cart-discount th,
tr.cart-discount td,
tr.order-total th,
tr.order-total td {
  text-align: right; }

.woocommerce form {
  margin-bottom: 0; }

.woocommerce-thankyou-order-details {
  margin-top: 20px;
  margin-bottom: 80px; }

/* line 207, ../scss/style.scss */
table {
  border-collapse: collapse;
  border-spacing: 0; }

div.cart-collaterals {
  margin-top: 0px; }

form.variations_form.cart, form.cart {
  display: block !important;
  margin-bottom: 1.5em; }

.woocommerce #content div.product form.cart .variations, .woocommerce div.product form.cart .variations, .woocommerce-page #content div.product form.cart .variations, .woocommerce-page div.product form.cart .variations {
  display: block; }

button.single_add_to_cart_button.button.alt {
  display: block; }

div.quantity.buttons_added, p.stock {
  display: none !important; }

/* Hand cursor on clickable input elements */
/* line 211, ../scss/style.scss */
label, input[type="button"], input[type="submit"], input[type="image"], button {
  cursor: pointer; }

/* Bicubic resizing for non-native sized IMG: code.flickr.com/blog/2008/11/12/on-ui-quality-the-little-things-client-side-image-resizing/ */
/* line 215, ../scss/style.scss */
.ie7 img {
  -ms-interpolation-mode: bicubic; }

div.addthis_toolbox.addthis_default_style {
  margin-top: 10px !important; }

/* www.sitepoint.com/blogs/2010/08/20/ie-remove-textarea-scrollbars/ */
/* line 219, ../scss/style.scss */
textarea {
  overflow: auto; }

/* Webkit browsers add a 2px margin outside the chrome of form elements */
/* line 223, ../scss/style.scss */
button, input, select, textarea {
  margin: 0; }

/* www.pathf.com/blogs/2008/05/formatting-quoted-code-in-blog-posts-css21-white-space-pre-wrap/ */
/* line 227, ../scss/style.scss */
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
  background: white;
  padding: 1em;
  margin-bottom: 1.618em; }

/* Normalize monospace sizing: en.wikipedia.org/wiki/MediaWiki_talk:Common.css/Archive_11#Teletype_style_fix_for_Chrome */
/* line 231, ../scss/style.scss */
pre, code, kbd, samp {
  font-family: monospace, "museo-sans-rounded", Helvetica, sans-serif; }

/* Accessible focus treatment: people.opera.com/patrickl/experiments/keyboard/test */
/* line 235, ../scss/style.scss */
a:hover, a:active {
  outline: none; }

/* Set sub, sup without affecting line-height: gist.github.com/413930 */
/* line 239, ../scss/style.scss */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative; }

/* line 241, ../scss/style.scss */
sup {
  top: -0.5em; }

/* line 243, ../scss/style.scss */
sub {
  bottom: -0.25em; }

/* Floating & Alignment  */
/* line 251, ../scss/style.scss */
.fl {
  float: left; }

/* line 253, ../scss/style.scss */
.fr {
  float: right; }

/* line 255, ../scss/style.scss */
.ac {
  text-align: center; }

/* line 257, ../scss/style.scss */
.ar {
  text-align: right; }

/* The Magnificent Clearfix: Updated to prevent margin-collapsing on child elements.

   j.mp/bestclearfix */
/* line 265, ../scss/style.scss */
.fix:before, .fix:after, .col-full:before, .col-full:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden; }

/* line 267, ../scss/style.scss */
.fix:after, .col-full:after {
  clear: both; }

/* Fix clearfix: blueprintcss.lighthouseapp.com/projects/15318/tickets/5-extra-margin-padding-bottom-of-page */
/* line 271, ../scss/style.scss */
.fix, .col-full {
  zoom: 1; }

/* line 275, ../scss/style.scss */
mark {
  background: none;
  color: #565656; }

/*-------------------------------------------------------------------------------------------*/
/* 1. SETUP */
/*-------------------------------------------------------------------------------------------*/
/* 1.1 Defaults */
/* line 297, ../scss/style.scss */
html {
  font-size: 62.5%; }

/* line 303, ../scss/style.scss */
body {
  font-size: 1.2em;
  line-height: 1.618;
  color: #121212;
  font-family: "museo-sans-rounded", Helvetica, sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  padding: 0 1.618em;
  background-color: #fafafa; }

INPUT#mc-embedded-subscribe.button {
  padding: 1px 0px 0px 0px;
  height: 30px !important;
  width: 90px !important;
  line-height: 30px;
  margin-top: -1px; }

table.shop_table.cart {
  border-top: 1px solid #111111; }

tr.cart_table_item, thead {
  border-bottom: 1px solid #111111; }

/* line 329, ../scss/style.scss */
img {
  vertical-align: bottom;
  max-width: 100%;
  height: auto; }

/* line 331, ../scss/style.scss */
hr {
  background-color: #666666;
  border: 0;
  height: 1px;
  margin-bottom: 1.618em; }

/* 1.2 Hyperlinks */
/* line 339, ../scss/style.scss */
a {
  text-decoration: none;
  font-weight: 300;
  color: #121212; }

/* line 349, ../scss/style.scss */
a:hover {
  text-decoration: none; }

/* line 360, ../scss/style.scss */
.summary a,
.headline a,
.entry p a {
  border-bottom: 1px solid #d1d1d1;
  -webkit-transition: border-color ease-in-out 0.2s;
  -moz-transition: border-color ease-in-out 0.2s;
  -ms-transition: border-color ease-in-out 0.2s;
  -o-transition: border-color ease-in-out 0.2s;
  transition: border-color ease-in-out 0.2s; }

/* line 366, ../scss/style.scss */
/* line 374, ../scss/style.scss */
.summary .addthis_toolbox a {
  border-bottom: 0px solid #d1d1d1; }

/* line 378, ../scss/style.scss */
.summary .addthis_toolbox a:hover {
  border-bottom: 0px solid #121212; }

/* line 386, ../scss/style.scss */
.clear {
  clear: both; }

dd.variation-Thisproductwillbeproducedanddeliveredwithin6to8weeks {
  display: none; }

dt.variation-Thisproductwillbeproducedanddeliveredwithin6to8weeks {
  position: relative; }
  dt.variation-Thisproductwillbeproducedanddeliveredwithin6to8weeks:before {
    display: block;
    content: " ";
    width: 6px;
    height: 14px;
    background: #fafafa;
    position: absolute;
    top: 0;
    right: -3px; }

/* 1.3 Typography */
/*

	All typography (and layout) based on a modular scale using 12 & 14 px values scaled in accordance the golden ratio (1.618)

	http://modularscale.com/scale/?px1=14&px2=12&ra1=1.618&ra2=0

	Remember to keep margins consistent and try to avoid margin-top. If everything has an appropriate margin-bottom we don't need one and vertical rhythm will be kept consistent

*/
/* line 408, ../scss/style.scss */
h1, h2, h3, h4, h5, h6 {
  font-family: "museo-sans-rounded", Helvetica, sans-serif;
  color: #121212;
  margin: 0;
  font-weight: 300;
  text-transform: uppercase; }

/* line 430, ../scss/style.scss */
h1 {
  font-size: 1.0833333333333333em;
  line-height: 1.387em;
  margin: 20px 0; }

/* line 440, ../scss/style.scss */
h1 span {
  padding: 0 0 10px 0;
  border-bottom: 1px solid #121212; }

/* line 450, ../scss/style.scss */
h2 {
  font-size: 1.3em;
  line-height: 1.387em; }

/* line 458, ../scss/style.scss */
h3 {
  font-weight: bold;
  margin-bottom: 13px;
  line-height: 1.387em; }

/* line 466, ../scss/style.scss */
h4 {
  font-size: 1em; }

/* line 472, ../scss/style.scss */
h5 {
  font-size: 1em; }

/* line 478, ../scss/style.scss */
h6 {
  font-size: 0.857em; }

/* line 484, ../scss/style.scss */
p {
  -webkit-hyphens: auto;
  -webkit-hyphenate-character: "\2010";
  -webkit-hyphenate-limit-after: 1;
  -webkit-hyphenate-limit-before: 3;
  -moz-hyphens: auto;
  orphans: 3;
  widows: 3;
  font-family: "museo-sans-rounded", Helvetica, sans-serif;
  color: #121212;
  margin: 0;
  font-weight: 300;
  line-height: 1.5em; }

/* line 530, ../scss/style.scss */
ul, ol, dl,
p,
table,
form,
pre,
hr {
  margin: 0 0 0 0; }

/* line 540, ../scss/style.scss */
li > ul,
li > ol {
  margin: 0 0 0 1.618em; }

/* line 550, ../scss/style.scss */
dl dt {
  font-weight: bold; }

/* line 556, ../scss/style.scss */
dl dd {
  margin-bottom: 1.618em; }

/* line 566, ../scss/style.scss */
blockquote {
  quotes: "\201C" "\201D";
  position: relative;
  color: black; }

/* line 574, ../scss/style.scss */
blockquote:before {
  content: "\201C";
  font-family: "museo-sans-rounded", Helvetica, sans-serif;
  font-size: 3.631em;
  position: absolute;
  top: -.327em;
  left: -.53em;
  color: #444444; }

/* 1.3.1 Typefaces */
@font-face {
  font-family: 'WebSymbolsRegular';
  src: url("../typefaces/websymbols-regular-webfont.eot");
  src: url("../typefaces/websymbols-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../typefaces/websymbols-regular-webfont.woff") format("woff"), url("../typefaces/websymbols-regular-webfont.ttf") format("truetype"), url("../typefaces/websymbols-regular-webfont.svg#WebSymbolsRegular") format("svg");
  font-weight: normal;
  font-style: normal;
  speak: none; }

@font-face {
  font-family: 'icomoon';
  src: url("../typefaces/icomoon.eot");
  src: url("../typefaces/icomoon.eot?#iefix") format("embedded-opentype"), url("../typefaces/icomoon.woff") format("woff"), url("../typefaces/icomoon.ttf") format("truetype"), url("../typefaces/icomoon.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  speak: none; }

/* 1.4 Tables */
/* line 646, ../scss/style.scss */
table {
  width: 100%;
  font-size: .857em;
  margin-bottom: 2.618em; }

/* line 654, ../scss/style.scss */
table th, table td {
  padding: 1em;
  vertical-align: top;
  text-align: left; }

/* line 666, ../scss/style.scss */
/* line 678, ../scss/style.scss */
table tr th:first-child, table tr td:first-child {
  padding-left: 0px; }

/* line 684, ../scss/style.scss */
table tr th:last-child, table tr td:last-child {
  padding-right: 0px; }

.payment-errors {
  width: 40%;
  float: right;
  text-align: right; }

/*-------------------------------------------------------------------------------------------*/
/* 2. MAGNIFIC POPUP CSS */
/*-------------------------------------------------------------------------------------------*/
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }

.mfp-preloader a {
  color: #CCC; }

.mfp-preloader a:hover {
  color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1; }

.mfp-close:active {
  top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }

.mfp-arrow:active {
  margin-top: -54px; }

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1; }

.mfp-arrow:before,
.mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent; }

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px; }

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px; }

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px; }

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px; }

.mfp-iframe-holder .mfp-close {
  top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444; }

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px; }

.mfp-figure figure {
  margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

/*-------------------------------------------------------------------------------------------*/
/* 2. SITE STRUCTURE & APPEARANCE */
/*-------------------------------------------------------------------------------------------*/
/* 2.1 Containers & Columns */
/* 2.2 Navigation */
/* line 720, ../scss/style.scss */
ul.nav li a {
  padding: .5em 1em;
  display: block; }

/* line 742, ../scss/style.scss */
.footer_logo {
  margin: 0 auto;
  width: 70px;
  height: 75px;
  display: block;
  text-indent: -9999px;
  background-position: -240px -196px; }

/* line 778, ../scss/style.scss */
.single-product #navigation ul li#menu-item-31 a span,
.term-handbags #navigation ul li#menu-item-31 a span,
.term-blankets #navigation ul li#menu-item-31 a span,
.term-carpets #navigation ul li#menu-item-31 a span,
.term-scarves #navigation ul li#menu-item-31 a span,
.term-laptopcases #navigation ul li#menu-item-31 a span {
  border-bottom: 1px solid #121212; }

/* line 796, ../scss/style.scss */
#menu-item-34 {
  position: relative; }

/* line 802, ../scss/style.scss */
#menu-item-34 a.mail_hover {
  display: none;
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  height: 100%;
  background: white; }

/* line 826, ../scss/style.scss */
#menu-item-34:hover a.mail_hover {
  display: block;
  border-bottom: 0px solid transparent;
  margin: 0;
  padding: 0;
  text-transform: lowercase; }

/* line 842, ../scss/style.scss */
#navigation {
  clear: both;
  margin-top: 88px;
  display: none; }

/* line 850, ../scss/style.scss */
#navigation ul {
  list-style: none; }

/* line 862, ../scss/style.scss */
#navigation ul li.facebook a:hover span {
  border-bottom: 0px solid #d1d1d1; }

/* line 878, ../scss/style.scss */
.tax-product_cat #navigation ul li.menu-item-object-product_cat a span,
.tax-product_cat #navigation ul li.menu-item-object-product_cat a span,
#navigation ul li.current-menu-item a span {
  border-bottom: 1px solid #121212; }

/* line 890, ../scss/style.scss */
#navigation ul li a {
  font-weight: 500; }

/* line 896, ../scss/style.scss */
#navigation ul li a.hovering {
  text-transform: lowercase; }

/* line 904, ../scss/style.scss */
#navigation ul li a > span {
  border-bottom: 1px solid transparent;
  -webkit-transition: border-bottom-color ease-in-out 0.2s;
  -moz-transition: border-bottom-color ease-in-out 0.2s;
  -ms-transition: border-bottom-color ease-in-out 0.2s;
  -o-transition: border-bottom-color ease-in-out 0.2s;
  transition: border-bottom-color ease-in-out 0.2s;
  display: block; }

/* line 918, ../scss/style.scss */
#navigation ul li a:hover > span {
  border-bottom: 1px solid black; }

/* 2.2.2 Top Navigation (optional) */
/* line 938, ../scss/style.scss */
#top {
  background: white;
  margin: 0 -1.618em;
  padding: 1.618em; }

/* line 41, ../scss/mixins.scss */
#top:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both; }

/* line 948, ../scss/style.scss */
#top .wc-nav {
  margin-bottom: 0;
  text-align: right; }

/* line 954, ../scss/style.scss */
#top .wc-nav li {
  list-style: none;
  display: inline-block; }

/* line 960, ../scss/style.scss */
#top .wc-nav li.checkout {
  display: none; }

/* line 966, ../scss/style.scss */
#top .wc-nav li.search {
  width: 48%;
  float: right; }

/* line 972, ../scss/style.scss */
#top .wc-nav li.search input {
  width: 100%;
  padding-left: 0; }

/* line 980, ../scss/style.scss */
#top .wc-nav li.search form {
  margin-bottom: 0; }

/* line 986, ../scss/style.scss */
#top .wc-nav li.search label, #top .wc-nav li.search .button {
  display: none; }

/* line 994, ../scss/style.scss */
#top .wc-nav li.cart {
  width: 48%;
  float: left;
  text-align: left; }

/* line 1002, ../scss/style.scss */
#top .wc-nav li.cart a {
  padding-top: .25em;
  padding-left: 0; }

/* line 1008, ../scss/style.scss */
#top .wc-nav li.cart a:before {
  font-family: 'WebSymbolsRegular';
  display: inline-block;
  font-size: .857em;
  margin-right: .618em;
  font-weight: normal;
  font-family: "icomoon";
  font-size: 1em;
  content: "£";
  font-weight: normal; }

/* line 1022, ../scss/style.scss */
#top .wc-nav li.cart a .contents {
  display: none; }

/* line 1036, ../scss/style.scss */
#top select {
  margin-top: .857em;
  width: 100%; }

/* line 1044, ../scss/style.scss */
#top #top-nav {
  display: none; }

/* line 1052, ../scss/style.scss */
#top li.cart mark {
  background: none;
  padding-left: 1em;
  color: inherit; }

/* line 1068, ../scss/style.scss */
.usesSprite {
  display: block;
  background-image: url("../images/qov_sprite.png");
  background-repeat: no-repeat;
  background-color: #fafafa;
  display: block;
  text-indent: -9999px; }

/* line 1086, ../scss/style.scss */
.usesSprite a {
  display: block;
  text-indent: -9999px; }

/* line 1098, ../scss/style.scss */
.svg .usesSprite {
  background-image: url("../images/qov_sprite.svg"); }

/* 2.3 Header */
/* line 1108, ../scss/style.scss */
.home #header {
  margin: 0 auto;
  padding: 0;
  clear: both;
  position: relative;
  transition: all .3s ease-in-out; }
  .home #header .col-full {
    position: relative;
    z-index: 2; }
  .home #header:before {
    content: " ";
    display: block;
    width: 100%;
    height: 0;
    position: absolute;
    top: 95px;
    left: 0;
    background-color: #fafafa;
    transition: all .3S ease-in-out; }

/* line 1118, ../scss/style.scss */
#header #logo {
  float: left;
  margin-bottom: 1.618em; }

/* line 1120, ../scss/style.scss */
#header .site-description {
  display: none;
  margin-bottom: 0; }

/* line 1126, ../scss/style.scss */
#header .site-description a {
  text-transform: none;
  font-weight: normal;
  text-decoration: none; }

/* line 1144, ../scss/style.scss */
#header .site-title {
  font-size: 60px;
  font-weight: bold;
  line-height: 1;
  margin: 42px 0;
  padding: 0;
  background-position: -217px -24px; }

/* line 1162, ../scss/style.scss */
#header .site-title a {
  display: block;
  text-indent: -9999px;
  width: 102px;
  height: 103px; }

/* line 1178, ../scss/style.scss */
#header hgroup {
  position: relative; }

/* line 41, ../scss/mixins.scss */
#header hgroup:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both; }

/* line 1186, ../scss/style.scss */
#header .nav-toggle {
  position: absolute;
  top: .53em;
  right: 0;
  width: 1.618em;
  height: 1.618em;
  cursor: pointer;
  font-family: "museo-sans-rounded", Helvetica, sans-serif;
  background-color: black;
  background-image: -webkit-gradient(linear, left top, left bottom, from(black), to(black));
  /* Saf4+, Chrome */
  background-image: -webkit-linear-gradient(top, black, black);
  /* Chrome 10+, Saf5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(top, black, black);
  /* FF3.6+ */
  background-image: -ms-linear-gradient(top, black, black);
  /* IE10 */
  background-image: -o-linear-gradient(top, black, black);
  /* Opera 11.10+ */
  background-image: linear-gradient(to bottom, #000000, #000000);
  border: 0.202em solid black;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  text-align: center;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), inset 0 -1px 0 black;
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), inset 0 -1px 0 black; }

/* line 1226, ../scss/style.scss */
#header .nav-toggle a {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.4); }

/* line 1232, ../scss/style.scss */
#header .nav-toggle a:hover {
  text-decoration: none; }

/* line 1242, ../scss/style.scss */
#header .nav-toggle span {
  display: none; }

/* line 1250, ../scss/style.scss */
#header .site-description {
  font-family: "museo-sans-rounded", Helvetica, sans-serif;
  font-size: 1em;
  color: #454545; }

/* line 1260, ../scss/style.scss */
#header #topad {
  float: right; }

/* line 1268, ../scss/style.scss */
#header #navigation ul {
  float: none; }

/* 2.4 Content */
/* 2.4.1 Slider */
/* 2.5 Sidebar */
/* line 1296, ../scss/style.scss */
#sidebar .secondary {
  float: left;
  margin-right: 20px; }

/* line 1302, ../scss/style.scss */
#sidebar .secondary.last {
  margin: 0; }

/* 2.6 Footer */
/* line 1312, ../scss/style.scss */
.footer-wrap #footer:first-child {
  border-top: 0; }

/* line 1360, ../scss/style.scss */
#footer-widgets {
  border-top: 1px solid #666666;
  padding: 3.631em 0; }

/* 2.7 Breadcrumbs */
/* line 1372, ../scss/style.scss */
#breadcrumbs {
  display: none;
  margin-bottom: 1.618em;
  padding-bottom: 1.618em;
  color: #676767;
  font-size: .857em;
  font-weight: bold; }

/* line 1386, ../scss/style.scss */
#breadcrumbs .sep {
  color: #898989;
  padding: 0 .618em; }

/*-------------------------------------------------------------------------------------------*/
/* 3. POSTS */
/*-------------------------------------------------------------------------------------------*/
/* line 1404, ../scss/style.scss */
#breadcrumb, .post .video {
  margin-bottom: 1.618em; }

/* line 1410, ../scss/style.scss */
.archive-header {
  display: block;
  float: left;
  width: 100%;
  margin: 0 0 1.618em;
  padding: 0 0 1em;
  font-weight: bold;
  border-bottom: #666666 4px double; }

/* line 1426, ../scss/style.scss */
.archive-header .archive-rss {
  float: none; }

/* line 1432, ../scss/style.scss */
.archive-header .archive-rss a:before {
  font-family: 'WebSymbolsRegular';
  display: inline-block;
  font-size: .857em;
  margin-right: .618em;
  font-weight: normal;
  content: "r";
  color: orange; }

/* line 1448, ../scss/style.scss */
.archive-description {
  clear: both;
  margin-bottom: 1.618em; }

/* line 1458, ../scss/style.scss */
.post, .type-page {
  margin: 0 0 3.631em;
  /* 3.1 Images */ }

/* line 1462, ../scss/style.scss */
.post .post-meta, .type-page .post-meta {
  margin: 0 0 1.618em 0;
  border-bottom: 4px double #666666; }

/* line 1468, ../scss/style.scss */
.post .post-meta li, .type-page .post-meta li {
  display: inline;
  font-size: .857em;
  text-transform: uppercase;
  margin-right: 1em; }

/* line 1478, ../scss/style.scss */
.post .post-meta li a, .type-page .post-meta li a {
  color: #676767; }

/* line 1482, ../scss/style.scss */
.post .post-meta li a:hover, .type-page .post-meta li a:hover {
  color: black; }

/* line 1492, ../scss/style.scss */
.post .post-meta li.post-category:before, .post .post-meta li.tags:before, .post .post-meta li.comments:before, .post .post-meta li.edit:before, .type-page .post-meta li.post-category:before, .type-page .post-meta li.tags:before, .type-page .post-meta li.comments:before, .type-page .post-meta li.edit:before {
  font-family: 'WebSymbolsRegular';
  display: inline-block;
  font-size: .857em;
  margin-right: .618em;
  font-weight: normal;
  text-transform: none;
  color: #898989; }

/* line 1504, ../scss/style.scss */
.post .post-meta li.post-category:before, .type-page .post-meta li.post-category:before {
  content: "F"; }

/* line 1510, ../scss/style.scss */
.post .post-meta li.tags:before, .type-page .post-meta li.tags:before {
  content: ","; }

/* line 1516, ../scss/style.scss */
.post .post-meta li.comments:before, .type-page .post-meta li.comments:before {
  content: "c"; }

/* line 1522, ../scss/style.scss */
.post .post-meta li.edit:before, .type-page .post-meta li.edit:before {
  content: "S"; }

/* line 1532, ../scss/style.scss */
.post .meta, .type-page .meta {
  display: none; }

/* line 1538, ../scss/style.scss */
.post .post-more, .type-page .post-more {
  clear: both; }

/* line 1544, ../scss/style.scss */
.post p.tags, .type-page p.tags {
  width: 100%;
  clear: both; }

/* line 1550, ../scss/style.scss */
.post p.tags:before, .type-page p.tags:before {
  font-family: 'WebSymbolsRegular';
  display: inline-block;
  font-size: .857em;
  margin-right: .618em;
  font-weight: normal;
  content: ","; }

/* line 1564, ../scss/style.scss */
.post img, .type-page img {
  padding: 0;
  border: none;
  background: #fff;
  max-width: 100%;
  height: auto !important;
  display: block; }

/* line 1580, ../scss/style.scss */
.post img.wp-smiley, .type-page img.wp-smiley {
  padding: 0;
  border: 0; }

/* line 1588, ../scss/style.scss */
.post .alignleft, .type-page .alignleft {
  float: left;
  margin: 0 1.618em 1em 0; }

/* line 1596, ../scss/style.scss */
.post .alignright, .type-page .alignright {
  float: right;
  margin: 0 0 1em 1.618em; }

/* line 1604, ../scss/style.scss */
.post .aligncenter, .type-page .aligncenter {
  display: block;
  text-align: center;
  margin: 0 auto 1.618em; }

/* line 1614, ../scss/style.scss */
.post .wp-caption, .type-page .wp-caption {
  text-align: center;
  background: #fff;
  border: 1px solid #666666;
  max-width: 100%;
  padding-top: .327em; }

/* line 1626, ../scss/style.scss */
.post .wp-caption img, .type-page .wp-caption img {
  margin: 0;
  padding: .53em;
  background: none;
  border: 0;
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */ }

/* line 1642, ../scss/style.scss */
.post .wp-caption-text, .type-page .wp-caption-text {
  margin: 0;
  padding: 0 .53em .53em .53em;
  text-align: center; }

/* line 1660, ../scss/style.scss */
.post header h1 {
  margin-bottom: 0; }

/* 3.2 Pagination / WP-Pagenavi / Woo-Pagination */
/* line 1674, ../scss/style.scss */
.nav-entries, .wp-pagenavi, .woo-pagination {
  padding: 1em 1.618em; }

/* line 1682, ../scss/style.scss */
.nav-entries a {
  display: block;
  color: #121212;
  text-decoration: none; }

/* line 1694, ../scss/style.scss */
.woo-pagination {
  margin: 0;
  text-align: center; }

/* line 1700, ../scss/style.scss */
.woo-pagination .page-numbers {
  display: inline-block;
  text-decoration: none;
  padding: .382em .53em;
  font-weight: bold;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; }

/* line 1714, ../scss/style.scss */
.woo-pagination .page-numbers:hover {
  background: #fff; }

/* line 1720, ../scss/style.scss */
.woo-pagination .page-numbers.current {
  background: black;
  color: #fff;
  padding: .382em .857em; }

/* 3.3 Single Post Author */
/* line 1738, ../scss/style.scss */
#post-author {
  background: white;
  padding: 1.618em;
  border-top: 1px solid #666666;
  clear: both; }

/* line 1748, ../scss/style.scss */
#post-author .profile-image {
  float: left;
  margin: 0 1.618em .382em 0;
  padding: .382em;
  border: 1px solid #666666;
  background: rgba(255, 255, 255, 0.7); }

/* 3.4 Post Entry Nav */
/* line 1768, ../scss/style.scss */
#post-entries {
  border-top: 1px solid #666666;
  border-bottom: 4px double #666666;
  padding: 1.618em;
  margin-bottom: 3.631em; }

/* 3.5 Connect */
/* line 1784, ../scss/style.scss */
#connect {
  background: white;
  padding: 1.618em;
  border-top: 1px solid #666666;
  clear: both; }

#connect .newsletter-form .email {
  width: 140px; }

/* line 1806, ../scss/style.scss */
#connect .social a {
  opacity: 0.8;
  -webkit-transition: all ease-in-out 0.2s;
  -moz-transition: all ease-in-out 0.2s;
  -ms-transition: all ease-in-out 0.2s;
  -o-transition: all ease-in-out 0.2s;
  transition: all ease-in-out 0.2s; }

/* line 1812, ../scss/style.scss */
#connect .social a:hover {
  opacity: 1; }

/* line 1822, ../scss/style.scss */
#connect .related-posts {
  border-left: 1px solid #666666;
  padding-left: 1em; }

/* line 1828, ../scss/style.scss */
#connect .related-posts ul {
  list-style: none; }

/* line 1838, ../scss/style.scss */
#connect .social a {
  display: inline-block;
  height: 28px;
  width: 32px;
  background-image: url("@{img_path}/ico-subscribe-social.png");
  background-repeat: no-repeat; }

/* line 1850, ../scss/style.scss */
#connect .social a.subscribe {
  background-position: 0px 0; }

/* line 1852, ../scss/style.scss */
#connect .social a.twitter {
  background-position: -30px 0; }

/* line 1854, ../scss/style.scss */
#connect .social a.facebook {
  background-position: -60px 0; }

/* line 1856, ../scss/style.scss */
#connect .social a.youtube {
  background-position: -90px 0; }

/* line 1858, ../scss/style.scss */
#connect .social a.flickr {
  background-position: -120px 0; }

/* line 1860, ../scss/style.scss */
#connect .social a.linkedin {
  background-position: -150px 0; }

/* line 1862, ../scss/style.scss */
#connect .social a.delicious {
  background-position: -180px 0; }

/* line 1864, ../scss/style.scss */
#connect .social a.googleplus {
  background-position: -210px 0; }

/* line 1874, ../scss/style.scss */
.widget #connect .fl, .widget #connect .fr {
  float: none; }

/* line 1886, ../scss/style.scss */
#main #connect .col-left, #main #connect .col-right {
  width: 48%; }

/*-------------------------------------------------------------------------------------------*/
/* 4. WIDGETS */
/*-------------------------------------------------------------------------------------------*/
/* 4.1 Generic Widgets */
/* line 1906, ../scss/style.scss */
.widget {
  margin-bottom: 2.618em; }

/* line 1910, ../scss/style.scss */
.widget h3 {
  color: black;
  border: 1px double #666666;
  border-width: 1px 0 4px;
  padding: .618em 0;
  font-family: "museo-sans-rounded", Helvetica, sans-serif; }

/* line 1924, ../scss/style.scss */
.widget ul {
  list-style: none; }

/* line 1932, ../scss/style.scss */
.widget:first-child h3 {
  border-top-width: 0;
  padding-top: 0; }

/* line 1948, ../scss/style.scss */
.widget.widget_recent_entries li:before, .widget.widget_recent_comments li:before, .widget.widget_archive li:before, .widget.widget_categories li:before, .widget.widget_meta li:before, .widget.widget_product_categories li:before, .widget.widget_links li:before, .widget.widget_nav_menu li:before, .widget.widget_login li:before, .widget.widget_layered_nav li:before {
  font-family: 'WebSymbolsRegular';
  display: inline-block;
  font-size: .857em;
  margin-right: .618em;
  font-weight: normal;
  content: ">";
  color: #898989;
  width: .857em; }

/* line 1968, ../scss/style.scss */
.widget.widget_layered_nav li.chosen:before {
  content: "×";
  color: #b85f56; }

/* line 1982, ../scss/style.scss */
.widget.widget_login label {
  display: block; }

/* line 1988, ../scss/style.scss */
.widget.widget_login .input-text {
  width: 100%; }

/* 4.2 Specific Widgets */
/* SEARCH FORM */
/* line 2004, ../scss/style.scss */
.widget_product_search, .widget_woo_search {
  position: relative; }

/* line 2008, ../scss/style.scss */
.widget_product_search label, .widget_woo_search label {
  display: none; }

/* FLICKR */
/* line 2024, ../scss/style.scss */
.widget_woo_flickr h3 span {
  color: #0051d8; }

/* line 2028, ../scss/style.scss */
.widget_woo_flickr h3 span span {
  color: #ff1183; }

/* line 2038, ../scss/style.scss */
.widget_woo_flickr .flickr_badge_image {
  float: left;
  margin: 0.236em; }

/* line 2046, ../scss/style.scss */
.widget_woo_flickr .wrap {
  position: relative;
  margin-bottom: 1.618em;
  padding: 0; }

/* line 2058, ../scss/style.scss */
.widget_woo_flickr a img {
  float: left;
  margin: 0 0 0.618em 0.618em;
  display: block;
  border: 1px solid #666666;
  padding: .236em; }

/* line 2074, ../scss/style.scss */
.widget_woo_flickr a:hover img {
  border-color: #4d4d4d; }

/* CALENDAR */
/* line 2090, ../scss/style.scss */
#wp-calendar {
  width: 95%;
  margin-bottom: 1.618em;
  clear: both;
  padding: 0; }

/* line 2100, ../scss/style.scss */
#wp-calendar caption {
  padding: 1em; }

/* line 2106, ../scss/style.scss */
#wp-calendar th, #wp-calendar td {
  text-align: center;
  background: #e6e6e6;
  padding: .236em 0; }

/* line 2116, ../scss/style.scss */
#wp-calendar td {
  background: transparent;
  color: #2c2c2c; }

/* BLOG AUTHOR */
/* line 2132, ../scss/style.scss */
.widget_woo_blogauthorinfo .avatar {
  padding: .382em;
  border: 1px solid #666666; }

/* line 2140, ../scss/style.scss */
.widget_woo_blogauthorinfo .left {
  float: left;
  margin: 0 1em .618em 0; }

/* line 2148, ../scss/style.scss */
.widget_woo_blogauthorinfo .right {
  float: right;
  margin: 0 .618em 1em; }

/* TWITTER */
/* line 2164, ../scss/style.scss */
.widget_woo_twitter .back {
  padding: 1em;
  background: white;
  -webkit-border-radius: 0.327em;
  border-radius: 0.327em;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; }

/* line 2174, ../scss/style.scss */
.widget_woo_twitter h3 {
  border-bottom: 0;
  margin: 0; }

/* line 2182, ../scss/style.scss */
.widget_woo_twitter ul {
  background: #fff;
  padding-left: 0;
  border: 1px solid #666666; }

/* line 2190, ../scss/style.scss */
.widget_woo_twitter ul li {
  border-bottom: 1px solid #666666;
  padding: 1em;
  list-style: none; }

/* line 2198, ../scss/style.scss */
.widget_woo_twitter ul li:last-child {
  border: none; }

/* line 2204, ../scss/style.scss */
.widget_woo_twitter ul li a {
  padding: 0; }

/* line 2210, ../scss/style.scss */
.widget_woo_twitter ul li .time {
  color: #2c2c2c; }

/* line 2220, ../scss/style.scss */
.widget_woo_twitter p {
  font-weight: bold;
  color: #2c2c2c; }

/* line 2226, ../scss/style.scss */
.widget_woo_twitter p a {
  color: black; }

/* line 2238, ../scss/style.scss */
.widget_woodojo_tabs ul.nav-tabs {
  margin-bottom: 0;
  margin-left: 0; }

/* line 2244, ../scss/style.scss */
.widget_woodojo_tabs ul.nav-tabs li {
  float: left;
  margin-bottom: 0; }

/* line 2250, ../scss/style.scss */
.widget_woodojo_tabs ul.nav-tabs li a {
  border-bottom: 0;
  background: fade(#666666, 50%);
  color: #121212;
  margin: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
  padding: .382em .53em !important; }

/* line 2268, ../scss/style.scss */
.widget_woodojo_tabs ul.nav-tabs li a:hover {
  color: black; }

/* line 2276, ../scss/style.scss */
.widget_woodojo_tabs ul.nav-tabs li.active {
  position: relative; }

/* line 2280, ../scss/style.scss */
.widget_woodojo_tabs ul.nav-tabs li.active:before {
  content: "";
  display: block;
  position: absolute;
  top: -.236em;
  left: 0;
  right: 0;
  height: .236em;
  background: black;
  border-top: 1px solid #111111;
  -webkit-border-top-left-radius: 2px;
  -webkit-border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; }

/* line 2304, ../scss/style.scss */
.widget_woodojo_tabs ul.nav-tabs li.active a {
  position: relative;
  background: #fff;
  box-shadow: 0 1px 2px 0 #666666;
  -webkit-box-shadow: 0 1px 2px 0 #666666;
  color: black;
  border-top-color: black; }

/* line 2316, ../scss/style.scss */
.widget_woodojo_tabs ul.nav-tabs li.active a:after {
  content: "";
  display: block;
  position: absolute;
  bottom: -4px;
  left: 0;
  right: 0;
  height: 4px;
  background: #fff; }

/*-------------------------------------------------------------------------------------------*/
/* 5. COMMENTS */
/*-------------------------------------------------------------------------------------------*/
/* 5.1 Comments */
/* line 2356, ../scss/style.scss */
#comments {
  position: relative; }

/* line 2362, ../scss/style.scss */
#comments .commentlist {
  clear: both;
  list-style: none;
  border-bottom: 4px double #666666;
  padding-top: 1.618em;
  margin-bottom: 3.631em;
  padding-bottom: 3.631em; }

/* line 41, ../scss/mixins.scss */
#comments .commentlist:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both; }

/* line 2378, ../scss/style.scss */
#comments .commentlist li.comment {
  margin-bottom: 1.618em;
  list-style: none; }

/* line 41, ../scss/mixins.scss */
#comments .commentlist li.comment:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both; }

/* line 41, ../scss/mixins.scss */
#comments .commentlist li.comment .comment-container:after, #comments .commentlist li.comment .comment_container:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both; }

/* line 2390, ../scss/style.scss */
#comments .commentlist li.comment .comment-container div.avatar, #comments .commentlist li.comment .comment_container div.avatar {
  width: 11%;
  float: left; }

/* line 2396, ../scss/style.scss */
#comments .commentlist li.comment .comment-container div.avatar img, #comments .commentlist li.comment .comment_container div.avatar img {
  -webkit-border-radius: 100%;
  border-radius: 100%;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; }

/* line 2404, ../scss/style.scss */
#comments .commentlist li.comment .comment-container .comment-entry, #comments .commentlist li.comment .comment-container .comment-head, #comments .commentlist li.comment .comment-container .comment-text, #comments .commentlist li.comment .comment_container .comment-entry, #comments .commentlist li.comment .comment_container .comment-head, #comments .commentlist li.comment .comment_container .comment-text {
  width: 82.7%;
  float: right; }

/* line 2412, ../scss/style.scss */
#comments .commentlist li.comment .comment-container .comment-head, #comments .commentlist li.comment .comment-container p.meta, #comments .commentlist li.comment .comment_container .comment-head, #comments .commentlist li.comment .comment_container p.meta {
  font-weight: bold;
  margin-bottom: .618em;
  color: #454545; }

/* line 2420, ../scss/style.scss */
#comments .commentlist li.comment .comment-container .comment-head .name, #comments .commentlist li.comment .comment-container .comment-head .date, #comments .commentlist li.comment .comment-container .comment-head strong, #comments .commentlist li.comment .comment-container p.meta .name, #comments .commentlist li.comment .comment-container p.meta .date, #comments .commentlist li.comment .comment-container p.meta strong, #comments .commentlist li.comment .comment_container .comment-head .name, #comments .commentlist li.comment .comment_container .comment-head .date, #comments .commentlist li.comment .comment_container .comment-head strong, #comments .commentlist li.comment .comment_container p.meta .name, #comments .commentlist li.comment .comment_container p.meta .date, #comments .commentlist li.comment .comment_container p.meta strong {
  margin-right: 1em; }

/* line 2424, ../scss/style.scss */
#comments .commentlist li.comment .comment-container .comment-head .name:before, #comments .commentlist li.comment .comment-container .comment-head .date:before, #comments .commentlist li.comment .comment-container .comment-head strong:before, #comments .commentlist li.comment .comment-container p.meta .name:before, #comments .commentlist li.comment .comment-container p.meta .date:before, #comments .commentlist li.comment .comment-container p.meta strong:before, #comments .commentlist li.comment .comment_container .comment-head .name:before, #comments .commentlist li.comment .comment_container .comment-head .date:before, #comments .commentlist li.comment .comment_container .comment-head strong:before, #comments .commentlist li.comment .comment_container p.meta .name:before, #comments .commentlist li.comment .comment_container p.meta .date:before, #comments .commentlist li.comment .comment_container p.meta strong:before {
  font-family: 'WebSymbolsRegular';
  display: inline-block;
  font-size: .857em;
  margin-right: .618em;
  font-weight: normal;
  font-weight: normal; }

/* line 2434, ../scss/style.scss */
#comments .commentlist li.comment .comment-container .comment-head .perma, #comments .commentlist li.comment .comment-container .comment-head .edit, #comments .commentlist li.comment .comment-container p.meta .perma, #comments .commentlist li.comment .comment-container p.meta .edit, #comments .commentlist li.comment .comment_container .comment-head .perma, #comments .commentlist li.comment .comment_container .comment-head .edit, #comments .commentlist li.comment .comment_container p.meta .perma, #comments .commentlist li.comment .comment_container p.meta .edit {
  margin-right: 1em; }

/* line 2440, ../scss/style.scss */
#comments .commentlist li.comment .comment-container .comment-head .name:before, #comments .commentlist li.comment .comment-container .comment-head strong:before, #comments .commentlist li.comment .comment-container p.meta .name:before, #comments .commentlist li.comment .comment-container p.meta strong:before, #comments .commentlist li.comment .comment_container .comment-head .name:before, #comments .commentlist li.comment .comment_container .comment-head strong:before, #comments .commentlist li.comment .comment_container p.meta .name:before, #comments .commentlist li.comment .comment_container p.meta strong:before {
  content: "U"; }

/* line 2446, ../scss/style.scss */
#comments .commentlist li.comment .comment-container .comment-head .date:before, #comments .commentlist li.comment .comment-container p.meta .date:before, #comments .commentlist li.comment .comment_container .comment-head .date:before, #comments .commentlist li.comment .comment_container p.meta .date:before {
  content: "P"; }

/* line 2452, ../scss/style.scss */
#comments .commentlist li.comment .comment-container .comment-head .perma a:before, #comments .commentlist li.comment .comment-container p.meta .perma a:before, #comments .commentlist li.comment .comment_container .comment-head .perma a:before, #comments .commentlist li.comment .comment_container p.meta .perma a:before {
  font-family: 'WebSymbolsRegular';
  display: inline-block;
  font-size: .857em;
  margin-right: .618em;
  font-weight: normal;
  font-weight: normal;
  content: "K"; }

/* line 2462, ../scss/style.scss */
#comments .commentlist li.comment .comment-container .comment-head .edit a:before, #comments .commentlist li.comment .comment-container p.meta .edit a:before, #comments .commentlist li.comment .comment_container .comment-head .edit a:before, #comments .commentlist li.comment .comment_container p.meta .edit a:before {
  font-family: 'WebSymbolsRegular';
  display: inline-block;
  font-size: .857em;
  margin-right: .618em;
  font-weight: normal;
  font-weight: normal;
  content: "S"; }

/* line 2478, ../scss/style.scss */
#comments .commentlist li.comment .comment_container img.avatar {
  -webkit-border-radius: 100%;
  border-radius: 100%;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  width: 4.85%;
  float: left; }

/* line 2488, ../scss/style.scss */
#comments .commentlist li.comment .comment_container .comment-text {
  width: 91.35%; }

/* line 2496, ../scss/style.scss */
#comments .commentlist li.comment ul.children {
  margin-left: 1.618em;
  padding-top: 1.618em; }

/* line 2504, ../scss/style.scss */
#comments .commentlist li.comment #respond {
  padding-top: 1.618em; }

/* line 2518, ../scss/style.scss */
#comments .navigation a {
  display: block;
  text-decoration: none; }

/* line 2530, ../scss/style.scss */
#comments .pingbacks li.pingback {
  margin: 0 0 1.618em; }

/* line 2534, ../scss/style.scss */
#comments .pingbacks li.pingback .reply {
  display: none; }

/* line 2544, ../scss/style.scss */
#comments .nocomments {
  padding: 1.618em 0;
  margin: 1.618em 0;
  border: 4px double #666666;
  border-right: 0;
  border-left: 0; }

/* 5.2 Comments Form */
/* line 2564, ../scss/style.scss */
#respond {
  margin-bottom: 3.631em; }

/* line 2568, ../scss/style.scss */
#respond label {
  color: #2c2c2c; }

/* line 2576, ../scss/style.scss */
#respond #reply-title small {
  display: block;
  margin: 0 0 1.618em;
  font-size: .618em;
  font-family: "museo-sans-rounded", Helvetica, sans-serif; }

/* line 2588, ../scss/style.scss */
#respond #reply-title small a:before {
  font-family: 'WebSymbolsRegular';
  display: inline-block;
  font-size: .857em;
  margin-right: .618em;
  font-weight: normal;
  content: "×"; }

/* line 2604, ../scss/style.scss */
#respond #commentform .comment-form-author, #respond #commentform .comment-form-email, #respond #commentform .comment-form-url {
  width: 30.75%;
  float: left;
  margin-right: 3.8%; }

/* line 2612, ../scss/style.scss */
#respond #commentform .comment-form-author label, #respond #commentform .comment-form-email label, #respond #commentform .comment-form-url label {
  display: inline-block; }

/* line 2618, ../scss/style.scss */
#respond #commentform .comment-form-author input, #respond #commentform .comment-form-email input, #respond #commentform .comment-form-url input {
  width: 100%; }

/* line 2626, ../scss/style.scss */
#respond #commentform .comment-form-url {
  margin-right: 0; }

/* line 2632, ../scss/style.scss */
#respond #commentform .comment-form-comment {
  clear: both; }

/* line 2638, ../scss/style.scss */
#respond #commentform label {
  display: block; }

/* line 2644, ../scss/style.scss */
#respond #commentform input.txt, #respond #commentform textarea {
  width: 100%; }

/* 5.3 Pingbacks / Trackbacks */
/* line 2660, ../scss/style.scss */
.pinglist li {
  margin: 0 0 0 1.618em;
  list-style-type: decimal; }

/* line 2666, ../scss/style.scss */
.pinglist li .author {
  font-weight: bold; }

/* line 2674, ../scss/style.scss */
.pinglist li .pingcontent {
  display: block;
  margin: 0 0 1.618em; }

/* line 2688, ../scss/style.scss */
.columns {
  -moz-column-count: 2;
  /* Firefox */
  -webkit-column-count: 2;
  /* Safari and Chrome */
  column-count: 2;
  -moz-column-gap: 40px;
  /* Firefox */
  -webkit-column-gap: 40px;
  /* Safari and Chrome */
  column-gap: 40px; }

/* line 2704, ../scss/style.scss */
.columns p {
  margin-bottom: 1.5em; }

/* line 2710, ../scss/style.scss */
.columns p:last-child {
  margin-bottom: 0; }

.page-template-for-checkout .checkout #order_review #payment ul {
  border-bottom: 1px solid #111111;
  padding-bottom: 20px; }

.page-template-for-checkout .cart_totals, .page-template-for-checkout .shipping_calculator {
  font-size: 12px; }

.page-template-for-checkout table th {
  text-transform: capitalize; }

.page-template-for-checkout input#ship-to-different-address-checkbox.input-checkbox {
  margin-left: 20px; }

.page-template-for-checkout div.form-row.place-order {
  margin-top: 30px !important; }

.page-template-for-checkout table.cart {
  margin-bottom: 0px; }

.page-template-for-checkout h3#order_review_heading {
  text-align: center; }

.page-template-for-checkout td.actions {
  display: none; }

.page-template-for-checkout div.cart-collaterals {
  margin-top: 30px !important;
  margin-bottom: 60px; }

.page-template-for-checkout form.shipping_calculator {
  display: none; }

.page-template-for-checkout input.input-text {
  width: 100% !important;
  border-radius: 0px !important; }

.page-template-for-checkout tr.shipping {
  border-bottom: 1px solid #111111 !important; }

/* line 2722, ../scss/style.scss */
.blogpost header {
  padding-top: 4px; }

/* line 2726, ../scss/style.scss */
.blogpost header .date {
  color: #acacac;
  font-size: 0.9166666666666em;
  line-height: 1.5em;
  text-transform: lowercase;
  font-weight: 300; }

/* line 2742, ../scss/style.scss */
.blogpost header h1 {
  color: #121212;
  font-weight: 300;
  font-size: 1.166666666666666em;
  line-height: 1.2em;
  text-transform: none;
  border-bottom: 1px solid #111111;
  margin: 0 0 4px 0;
  padding: 0px 0 6px 0; }

/* line 2768, ../scss/style.scss */
.blogpost .entry p {
  margin: 0;
  padding: 0.75em 0 0 0; }

/* line 2782, ../scss/style.scss */
.bloggers {
  padding: 0 102px; }
  .bloggers img {
    display: block;
    margin: 1.5em auto; }

.pagination {
  margin-top: 20px;
  margin-bottom: 40px; }

/* line 2790, ../scss/style.scss */
h1.headline {
  text-transform: none;
  text-align: center; }

.page-template-page-storyOLD-php h1.headline {
  font-size: 1.3333333em;
  line-height: 1.6875em;
  margin: 0px 240px 40px 240px;
  text-align: center;
  padding: 20px 0;
  position: relative; }

/*-------------------------------------------------------------------------------------------*/
/* 6. PAGE TEMPLATES */
/*-------------------------------------------------------------------------------------------*/
/* 6.1 Timeline Page Template */
/* line 2874, ../scss/style.scss */
#archives .archives_list {
  border-left: 1px solid #666666;
  list-style: none;
  margin: 0 0 1em 1em; }

/* line 2882, ../scss/style.scss */
#archives .archives_list li {
  clear: left;
  padding-left: 2.327em;
  font-style: normal;
  list-style: none; }

/* line 2894, ../scss/style.scss */
#archives .archives_list .date {
  color: #2c2c2c;
  width: 22.5%;
  display: inline-block; }

/* line 2904, ../scss/style.scss */
#archives .archives_list .linked {
  width: 48%;
  display: inline-block; }

/* line 2912, ../scss/style.scss */
#archives .archives_list .comments {
  width: 22.5%;
  display: inline-block; }

/* line 2920, ../scss/style.scss */
#archives .archives_list .comments a:hover {
  text-decoration: none; }

/* line 2926, ../scss/style.scss */
#archives .archives_list .comments a:before {
  font-family: 'WebSymbolsRegular';
  display: inline-block;
  font-size: .857em;
  margin-right: .618em;
  font-weight: normal;
  content: "c"; }

/* line 2942, ../scss/style.scss */
h3.archive_year {
  font-weight: bold;
  color: #121212;
  margin-top: 0; }

/* 6.2 Contact Form */
/* line 2958, ../scss/style.scss */
#contact-page .screenReader {
  left: -9999px;
  position: absolute;
  top: -9999px; }

/* line 2968, ../scss/style.scss */
#contact-page ol.forms {
  float: left;
  list-style: none;
  width: 100%; }

/* line 2976, ../scss/style.scss */
#contact-page ol.forms li {
  clear: both;
  float: left;
  margin-bottom: 1.618em;
  position: relative;
  width: 48%; }

/* line 2988, ../scss/style.scss */
#contact-page ol.forms li:first-child {
  margin-right: 3.8%; }

/* line 2994, ../scss/style.scss */
#contact-page ol.forms li:nth-child(2n) {
  clear: none; }

/* line 3000, ../scss/style.scss */
#contact-page ol.forms li.textarea, #contact-page ol.forms li.screenReader, #contact-page ol.forms li.inline, #contact-page ol.forms li.buttons {
  width: 100%; }

/* line 3006, ../scss/style.scss */
#contact-page ol.forms li input.txt, #contact-page ol.forms li textarea {
  width: 100%; }

/* line 3012, ../scss/style.scss */
#contact-page ol.forms li .error {
  display: block;
  color: red; }

/* line 3022, ../scss/style.scss */
#contact-page ol.forms li.textarea .error {
  display: block; }

/* line 3030, ../scss/style.scss */
#contact-page ol.forms li.screenReader {
  margin-bottom: 0; }

/* line 3038, ../scss/style.scss */
#contact-page ol.forms li.inline input {
  width: auto; }

/* line 3044, ../scss/style.scss */
#contact-page ol.forms li.inline input[type=checkbox] {
  width: 1.618em; }

/* line 3050, ../scss/style.scss */
#contact-page ol.forms li.inline label {
  display: inline;
  float: none;
  width: auto; }

/* line 3064, ../scss/style.scss */
#contact-page ol.forms label {
  cursor: pointer;
  display: block;
  font-weight: 700;
  margin: 0 0 1em; }

/* line 3076, ../scss/style.scss */
#contact-page ol.forms input#sendCopy {
  border: none;
  margin-right: 1em; }

/* line 3088, ../scss/style.scss */
.entry #contact-page ol.forms li {
  list-style: none; }

/* line 3096, ../scss/style.scss */
.page-template-template-contact-php #single_map_canvas img {
  max-width: inherit; }

/* line 3098, ../scss/style.scss */
.page-template-template-contact-php .location-twitter {
  margin: 0 0 1em;
  padding: 0 0 1.618em;
  border-bottom: 1px solid #666666; }

/* line 3100, ../scss/style.scss */
.page-template-template-contact-php .location-twitter .col-left {
  float: none; }

/* line 3112, ../scss/style.scss */
.page-template-template-contact-php .location-twitter ul {
  margin: 0;
  padding: 0; }

/* line 3120, ../scss/style.scss */
.page-template-template-contact-php .location-twitter ul li {
  list-style: none;
  padding: 0; }

/* line 3128, ../scss/style.scss */
.page-template-template-contact-php .location-twitter #office-location {
  margin: 0 0 1.618em; }

/* line 3134, ../scss/style.scss */
.page-template-template-contact-php .location-twitter #office-location ul li {
  margin: 0 0 1em; }

/* line 3144, ../scss/style.scss */
.page-template-template-contact-php .location-twitter .contact-social {
  float: none; }

/* line 3148, ../scss/style.scss */
.page-template-template-contact-php .location-twitter .contact-social #twitter {
  margin: 0 0 1.618em; }

/* line 3156, ../scss/style.scss */
.page-template-template-contact-php .location-twitter .contact-social #twitter ul li a.time {
  color: #383838; }

/* line 3168, ../scss/style.scss */
.page-template-template-contact-php .location-twitter .contact-social #connect {
  background: none;
  border: none;
  border-top: 1px solid #666666; }

/* line 3176, ../scss/style.scss */
.page-template-template-contact-php .location-twitter .contact-social #connect h3 {
  display: none; }

/* line 3182, ../scss/style.scss */
.page-template-template-contact-php .location-twitter .contact-social #connect .social {
  float: none; }

/* 6.3 Image Gallery */
/* line 3200, ../scss/style.scss */
.image-gallery-item img {
  max-width: 100%;
  padding: .236em;
  margin: 0 1em 1em 0;
  border: 1px solid #e6e6e6;
  background: white;
  -webkit-border-radius: 0.236em;
  border-radius: 0.236em;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; }

/* line 3214, ../scss/style.scss */
.image-gallery-item img:hover {
  border: 1px solid #cccccc;
  box-shadow: 0 1px 0.327em 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 0.327em 0px rgba(0, 0, 0, 0.2); }

/*-------------------------------------------------------------------------------------------*/
/* 7. WOOCOMMERCE */
/*-------------------------------------------------------------------------------------------*/
/* line 3236, ../scss/style.scss */
p.demo_store {
  background: #4d65a4;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  color: #fff;
  padding: 1em; }

/* line 3262, ../scss/style.scss */
.homepage-banner {
  display: none; }

/* line 3274, ../scss/style.scss */
.star-rating {
  overflow: hidden;
  position: relative;
  height: 1em;
  line-height: 1em;
  font-size: 1em;
  width: 5.5em;
  font-family: "WebSymbolsRegular"; }

/* line 3290, ../scss/style.scss */
.star-rating:before {
  content: "RRRRR";
  float: left;
  top: 0;
  left: 0;
  position: absolute;
  color: #898989; }

/* line 3306, ../scss/style.scss */
.star-rating span {
  overflow: hidden;
  float: left;
  top: 0;
  left: 0;
  position: absolute;
  padding-top: 1.5em; }

/* line 3322, ../scss/style.scss */
.star-rating span:before {
  content: "RRRRR";
  top: 0;
  position: absolute;
  left: 0;
  color: black; }

/* line 3342, ../scss/style.scss */
.widget .star-rating {
  margin: .53em 0; }

/* line 3352, ../scss/style.scss */
.comment-form-rating {
  clear: both; }

/* line 3360, ../scss/style.scss */
p.stars {
  position: relative;
  line-height: 1em;
  font-size: 1em; }

/* line 41, ../scss/mixins.scss */
p.stars:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both; }

/* line 3372, ../scss/style.scss */
p.stars a {
  width: 19%;
  display: block;
  position: relative;
  float: left;
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */
  padding: .5em .5em .5em .75em;
  background: rgba(0, 0, 0, 0.025);
  margin-right: 1%;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; }

/* line 3392, ../scss/style.scss */
p.stars a:hover, p.stars a.active {
  background: rgba(0, 0, 0, 0.075); }

/* line 3402, ../scss/style.scss */
p.stars a.star-1:after, p.stars a.star-2:after, p.stars a.star-3:after, p.stars a.star-4:after, p.stars a.star-5:after {
  font-family: "WebSymbolsRegular";
  content: "R";
  position: absolute;
  top: .85em;
  width: 6em;
  right: 1em;
  font-size: .5em;
  -webkit-border-radius: 1em;
  border-radius: 1em;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  color: #999;
  text-align: right; }

/* line 3426, ../scss/style.scss */
p.stars a.star-1:hover:after, p.stars a.star-1.active:after, p.stars a.star-2:hover:after, p.stars a.star-2.active:after, p.stars a.star-3:hover:after, p.stars a.star-3.active:after, p.stars a.star-4:hover:after, p.stars a.star-4.active:after, p.stars a.star-5:hover:after, p.stars a.star-5.active:after {
  color: black; }

/* line 3434, ../scss/style.scss */
p.stars a.star-1:after {
  content: "R"; }

/* line 3440, ../scss/style.scss */
p.stars a.star-2:after {
  content: "RR"; }

/* line 3446, ../scss/style.scss */
p.stars a.star-3:after {
  content: "RRR"; }

/* line 3452, ../scss/style.scss */
p.stars a.star-4:after {
  content: "RRRR"; }

/* line 3458, ../scss/style.scss */
p.stars a.star-5:after {
  content: "RRRRR"; }

.woocommerce-thankyou-order-received {
  text-align: center; }

.cart-empty {
  text-align: center; }

.return-to-shop {
  text-align: center; }
  .return-to-shop .button {
    display: inline-block;
    margin: 10px auto; }

/* line 3474, ../scss/style.scss */
div.quantity {
  white-space: nowrap;
  /* Disable input[type=number] buttons until the world is ready */ }

/* line 3480, ../scss/style.scss */
div.quantity input::-webkit-outer-spin-button, div.quantity input::-webkit-inner-spin-button {
  display: none; }

/* line 3486, ../scss/style.scss */
div.quantity .plus, div.quantity .minus {
  font-family: 'WebSymbolsRegular';
  background: none;
  border: none;
  font-size: 1.387em;
  padding: 0;
  color: black;
  -webkit-transition: all ease-in-out 0.2s;
  -moz-transition: all ease-in-out 0.2s;
  -ms-transition: all ease-in-out 0.2s;
  -o-transition: all ease-in-out 0.2s;
  transition: all ease-in-out 0.2s; }

/* line 3502, ../scss/style.scss */
div.quantity .plus:hover, div.quantity .minus:hover {
  color: black; }

/* line 3510, ../scss/style.scss */
div.quantity .qty {
  margin: 0 .618em;
  width: 2.618em;
  text-align: center;
  padding-left: 0;
  padding-right: 0; }

/* line 3528, ../scss/style.scss */
table div.quantity {
  font-size: .857em; }

.single-product div.quantity {
  float: left;
  margin-right: 1.618em;
  border-right: 4px double #666666;
  padding-right: 1.618em;
  display: none !important; }

/* line 3552, ../scss/style.scss */
.single-product table div.quantity {
  padding-right: 0;
  border: 0; }

/* line 3564, ../scss/style.scss */
.single-product #reviews .star-rating {
  float: right; }

/* line 3574, ../scss/style.scss */
.single-product .single_variation .price {
  display: block;
  margin-bottom: .618em; }

/* line 3588, ../scss/style.scss */
dl.variation dt, dl.variation dd {
  float: left; }

/* line 3594, ../scss/style.scss */
dl.variation dt {
  clear: left;
  margin-right: .53em; }

/* line 3604, ../scss/style.scss */
dl.variation dd ul {
  list-style: none; }

/* line 3614, ../scss/style.scss */
.backorder_notification {
  clear: both; }

/* line 3624, ../scss/style.scss */
.validate-required.woocommerce-validated input {
  border-color: #84ac50;
  background: #e5eeda; }

/* line 3632, ../scss/style.scss */
.validate-required.woocommerce-invalid input {
  border-color: #b85f56;
  background: #f4e7e6; }

/* 7.1 Messages */
/* line 3646, ../scss/style.scss */
.woocommerce_message, .woocommerce_info, .woocommerce_error, .woocommerce-message, .woocommerce-info, .woocommerce-error {
  padding: .618em 1em .618em 2.618em;
  margin-bottom: 1.618em;
  background: #fff;
  border: 1px solid #4d65a4;
  border-left-width: 0.382em;
  position: relative;
  font-weight: bold;
  display: none; }

/* line 3662, ../scss/style.scss */
.woocommerce_message:before, .woocommerce_info:before, .woocommerce_error:before, .woocommerce-message:before, .woocommerce-info:before, .woocommerce-error:before {
  font-family: 'WebSymbolsRegular';
  content: "S";
  color: #4d65a4;
  font-weight: normal;
  position: absolute;
  top: .618em;
  font-size: 1em;
  left: .857em;
  display: none !important; }

div.woocommerce-message {
  display: none !important; }

/* line 3686, ../scss/style.scss */
.woocommerce_error, .woocommerce-error {
  border-color: #b85f56;
  list-style: none; }

/* line 3692, ../scss/style.scss */
.woocommerce_error:before, .woocommerce-error:before {
  content: "W";
  color: #b85f56; }

/* line 3702, ../scss/style.scss */
.woocommerce_message, .woocommerce-message {
  border-color: #84ac50; }

/* line 3706, ../scss/style.scss */
.woocommerce_message:before, .woocommerce-message:before {
  content: ".";
  color: #84ac50; }

/* line 3714, ../scss/style.scss */
.woocommerce_message .button, .woocommerce-message .button {
  float: right;
  font-size: .857em; }

/* line 3734, ../scss/style.scss */
.js .bloggers img,
.js .home #wrapper img,
.js .products img,
.js #current_product .images img {
  opacity: 0; }

/* line 3744, ../scss/style.scss */
.loading_img {
  background: white;
  display: block; }

/* 7.2 Products */
/* line 3758, ../scss/style.scss */
.price del {
  opacity: 0.5; }

/* line 3764, ../scss/style.scss */
.price ins {
  text-decoration: none;
  font-weight: bold;
  margin-left: .618em;
  color: #b85f56; }

/* line 3778, ../scss/style.scss */
ul.products {
  margin-right: -10px !important;
  margin-left: -10px !important;
  list-style: none; }

/* line 41, ../scss/mixins.scss */
ul.products:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both; }

/* line 3786, ../scss/style.scss */
ul.products li.product {
  position: relative;
  float: left;
  width: 48%;
  margin-right: 3.8%;
  margin-bottom: 1.618em;
  text-align: left; }

/* line 3802, ../scss/style.scss */
ul.products li.product .woocommerce-loop-product__title {
  margin-bottom: 0;
  text-transform: none;
  font-size: 1.0833333333em;
  font-weight: 500;
  margin-top: 3px; }

/* line 3816, ../scss/style.scss */
ul.products li.product a {
  display: block; }

/* line 3826, ../scss/style.scss */
ul.products li.product a:hover h3 {
  color: black; }

/* line 3834, ../scss/style.scss */
ul.products li.product .price {
  color: #454545;
  margin-bottom: 1em; }

/* line 3844, ../scss/style.scss */
ul.products li.product img {
  width: 100%;
  height: auto;
  padding: 0;
  display: block;
  background: #fff;
  margin: 0; }

/* line 3860, ../scss/style.scss */
/*
    ul.products li.product:nth-child(2n) {
      margin-left: 20px;
      margin-right: 0; }
      */
/* line 3868, ../scss/style.scss */
ul.products .star-rating {
  margin: .5em auto; }

/* line 3876, ../scss/style.scss */
.woocommerce-ordering {
  display: none; }

/* line 3882, ../scss/style.scss */
.pagination-wrap {
  margin-bottom: 1.618em;
  padding-top: 1.618em;
  border-top: 4px double #666666; }

/* line 41, ../scss/mixins.scss */
.pagination-wrap:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both; }

/* line 3892, ../scss/style.scss */
.pagination-wrap .pagination, .pagination-wrap .woocommerce_ordering, .pagination-wrap .woocommerce-ordering {
  width: 48%; }

/* line 3898, ../scss/style.scss */
.pagination-wrap .pagination {
  float: left;
  margin: 0;
  text-align: left;
  padding: 0; }

/* line 3910, ../scss/style.scss */
.pagination-wrap .woocommerce_ordering, .pagination-wrap .woocommerce-ordering {
  float: right;
  text-align: right;
  padding-top: .53em; }

/* line 3922, ../scss/style.scss */
.woocommerce_result_count, .woocommerce_ordering, .woocommerce-result-count, .woocommerce-ordering {
  width: 48%;
  float: left; }

/* line 3928, ../scss/style.scss */
.woocommerce_result_count + ul.products, .woocommerce_ordering + ul.products, .woocommerce-result-count + ul.products, .woocommerce-ordering + ul.products {
  clear: both; }

/* line 3936, ../scss/style.scss */
.woocommerce-result-count {
  display: none; }

/* line 3942, ../scss/style.scss */
.woocommerce_ordering, .woocommerce-ordering {
  text-align: right;
  float: right; }

/* 7.2.1 Single Product */
/* line 3952, ../scss/style.scss */
.product {
  position: relative; }

/* line 3958, ../scss/style.scss */
.onsale {
  position: absolute;
  top: 0;
  right: 0;
  background: black;
  color: #fff;
  font-weight: bold;
  padding: 0 .857em;
  line-height: 4.236em;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  text-transform: uppercase; }

/* line 4000, ../scss/style.scss */
table.variations tr:last-child td, table.variations tr:last-child th {
  border-bottom: 0; }

/* line 4012, ../scss/style.scss */
.images .loading_img {
  margin-bottom: 20px; }

/* line 4018, ../scss/style.scss */
.images img {
  background: #fff;
  width: 100%; }

/* line 4026, ../scss/style.scss */
.images .thumbnails {
  border: 1px solid #666666;
  border-top: 0; }

/* line 41, ../scss/mixins.scss */
.images .thumbnails:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both; }

/* line 4034, ../scss/style.scss */
.images .thumbnails a {
  display: block;
  width: 25%;
  text-align: center;
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */
  float: left;
  border-right: 1px solid #666666; }

/* line 4048, ../scss/style.scss */
.images .thumbnails a img {
  border: 0;
  display: block;
  margin: 0 auto;
  opacity: 0.5; }

/* line 4062, ../scss/style.scss */
.images .thumbnails a:hover img {
  opacity: 1; }

/* line 4070, ../scss/style.scss */
.images .thumbnails a:last-child {
  border-right: 0; }

/* line 4084, ../scss/style.scss */
.product_meta {
  display: none; }

/* line 4092, ../scss/style.scss */
.related h2 {
  margin: 0 0 6px 0;
  padding: 0;
  font-size: 1.16666666666em;
  text-transform: uppercase;
  font-weight: 500; }

/* line 4110, ../scss/style.scss */
.summary h1 {
  margin: 0;
  padding: 0;
  font-size: 1.16666666666em;
  text-transform: uppercase;
  font-weight: 500; }

/* line 4126, ../scss/style.scss */
.summary p,
.summary ul,
.summary ol {
  font-size: 1em;
  color: #121212;
  font-weight: 300;
  margin-bottom: 1.5em; }

.summary .shipping_returns {
  border-top: 1px solid #555555;
  border-bottom: 1px solid #555555;
  padding: 1em 0; }
  .summary .shipping_returns .extra_info {
    cursor: normal;
    text-transform: none;
    clear: both;
    padding: 5px 0;
    display: none; }
    .summary .shipping_returns .extra_info h2 {
      font-weight: 400;
      font-size: 1em;
      cursor: pointer;
      text-align: center;
      margin: 1.5em 0;
      padding: 0; }
    .summary .shipping_returns .extra_info p {
      margin-bottom: 0.75em; }
  .summary .shipping_returns .header {
    margin-bottom: 0; }
  .summary .shipping_returns p {
    font-weight: 300;
    cursor: pointer;
    height: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
    .summary .shipping_returns p .arrow {
      float: right;
      margin-right: 10px; }

.summary .pre-order {
  padding: 1.5em 0 3em 0; }
  .summary .pre-order p {
    margin-bottom: 0.5em;
    font-weight: 400; }

/* line 4140, ../scss/style.scss */
.summary ul, .summary ol {
  padding-left: 15px; }

/* line 4150, ../scss/style.scss */
.summary ul:last-child,
.summary p:last-child {
  margin-bottom: 0; }

/* line 4158, ../scss/style.scss */
.summary .price .amount {
  font-size: 1em;
  color: #121212;
  font-weight: 500;
  margin-bottom: 1.5em; }

/* line 4172, ../scss/style.scss */
.summary form.cart {
  display: none; }

/* line 4182, ../scss/style.scss */
.summary .stock:before {
  font-family: 'WebSymbolsRegular';
  display: inline-block;
  font-size: .857em;
  margin-right: .618em;
  font-weight: normal;
  content: ".";
  color: #84ac50; }

/* line 4192, ../scss/style.scss */
.summary .stock.out-of-stock:before {
  content: "W";
  color: #b85f56; }

/* line 4200, ../scss/style.scss */
.summary .stock.available-on-backorder:before {
  content: "P";
  color: #4d65a4; }

/* line 4212, ../scss/style.scss */
.summary .group_table .stock {
  margin-left: 1em; }

/* line 4232, ../scss/style.scss */
.svg #current_product #next_prev_product .previous a,
.svg #current_product #next_prev_product .next a {
  background-image: url("../images/qov_sprite.svg"); }

/* line 4246, ../scss/style.scss */
#product_nav_border {
  display: none; }

.home #header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%; }

.home #header .usesSprite {
  background-color: transparent; }

.mfp-counter {
  display: none; }

.mfp-arrow-right:before,
.mfp-arrow-left:before,
.mfp-arrow-right:after,
.mfp-arrow-left:after {
  display: none; }

button.mfp-arrow-right,
button.mfp-arrow-left,
button.mfp-arrow-right:active,
button.mfp-arrow-left:active {
  width: 96px;
  height: 126px;
  padding: 30px;
  margin-top: -63px;
  background-image: url("../images/qov_sprite.svg");
  background-repeat: no-repeat; }

button.mfp-arrow-right {
  right: 30px;
  background-position: -625px -313px; }

button.mfp-arrow-left {
  left: 30px;
  background-position: -818px -313px; }

.mfp-image-holder button.mfp-close {
  position: fixed;
  right: 30px;
  top: 30px;
  color: transparent;
  width: 30px;
  height: 30px;
  background-image: url("../images/qov_sprite.svg");
  background-repeat: no-repeat;
  background-position: -754px -354px; }

@media only screen and (min-width: 1040px) {
  /* line 4256, ../scss/style.scss */
  .affixed #navigation {
    margin-top: 25px; }
  /* line 4262, ../scss/style.scss */
  .affixed #header {
    background: #fafafa;
    opacity: 1;
    position: fixed;
    left: 50%;
    margin-left: -460px;
    z-index: 1000; }
  /* line 4278, ../scss/style.scss */
  .affixed #header .site-title {
    background-position: -217px -108px;
    margin: 21px 0; }
  /* line 4286, ../scss/style.scss */
  .affixed #header .site-title a {
    height: 23px; }
  /* line 4298, ../scss/style.scss */
  .affixed #product_nav {
    opacity: 1;
    position: fixed;
    left: 50%;
    margin-left: -460px;
    z-index: 2000;
    top: 65px;
    width: 920px; }
  /* line 4318, ../scss/style.scss */
  .affixed #product_nav_border {
    opacity: 1;
    position: fixed;
    left: 50%;
    margin-left: -460px;
    z-index: 2000;
    top: 85px;
    width: 920px;
    background: #fafafa;
    display: block;
    height: 20px; }
  /* line 4344, ../scss/style.scss */
  .affixed #content {
    padding-top: 187px; }
  /* line 4356, ../scss/style.scss */
  .single.affixed #content {
    padding-top: 227px; }
  /* line 4362, ../scss/style.scss */
  /* line 4386, ../scss/style.scss */
  .single.affixed #current_product #next_prev_product .previous,
  .single.affixed #current_product #next_prev_product .next {
    position: fixed;
    top: 225px; }
  /* line 4394, ../scss/style.scss */
  .single.affixed #current_product #next_prev_product .previous {
    left: 50%;
    margin-left: -455px; }
  /* line 4402, ../scss/style.scss */
  .single.affixed #current_product #next_prev_product .next {
    left: 50%;
    margin-left: 405px; }
  /* line 4420, ../scss/style.scss */
  /* line 4448, ../scss/style.scss */
  .single.affixed.endofsingleproduct #current_product #next_prev_product .previous,
  .single.affixed.endofsingleproduct #current_product #next_prev_product .next {
    opacity: 0; } }

/* line 4464, ../scss/style.scss */
#current_product {
  padding: 0 102px;
  position: relative;
  list-style: none; }

/* line 41, ../scss/mixins.scss */
#current_product:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both; }

/* line 4480, ../scss/style.scss */
#current_product #next_prev_product .previous,
#current_product #next_prev_product .next {
  display: block;
  width: 50px;
  height: 88px;
  position: absolute;
  top: 120px;
  margin: 0;
  padding: 0px;
  opacity: 1;
  /*
    
    			opacity: .3333;			
    
    			*/
  -webkit-transition: opacity ease-in-out 0.1s;
  -moz-transition: opacity ease-in-out 0.1s;
  -ms-transition: opacity ease-in-out 0.1s;
  -o-transition: opacity ease-in-out 0.1s;
  transition: opacity ease-in-out 0.1s; }

/* line 4510, ../scss/style.scss */
#current_product #next_prev_product .previous:hover,
#current_product #next_prev_product .next:hover {
  opacity: .8; }

/* line 4516, ../scss/style.scss */
#current_product #next_prev_product .previous a,
#current_product #next_prev_product .next a {
  width: 50px;
  height: 88px;
  background-image: url("../images/qov_sprite.png");
  background-repeat: no-repeat;
  display: block;
  text-indent: -9999px; }

/* line 4538, ../scss/style.scss */
#current_product #next_prev_product .previous {
  left: 5px; }

/* line 4542, ../scss/style.scss */
#current_product #next_prev_product .previous a {
  background-position: -820px -30px; }

/* line 4550, ../scss/style.scss */
#current_product #next_prev_product .next {
  right: 5px; }

/* line 4554, ../scss/style.scss */
#current_product #next_prev_product .next a {
  background-position: -620px -30px; }

/* line 4572, ../scss/style.scss */
.single-product .images, .single-product .summary {
  margin-bottom: 80px; }

/* line 4580, ../scss/style.scss */
.single-product .summary .price_holder {
  margin: 20px 0; }

/* line 4584, ../scss/style.scss */
.single-product .summary .price .amount {
  font-size: 1em;
  color: #121212;
  font-weight: 500; }

/* line 4596, ../scss/style.scss */
.single-product .summary .product_meta {
  font-size: .857em;
  color: #454545;
  clear: both; }

/* line 4604, ../scss/style.scss */
.single-product .summary .product_meta a {
  color: #121212; }

/* line 4612, ../scss/style.scss */
.single-product .summary .social {
  border-top: 4px double #666666;
  padding-top: 1.618em;
  margin-top: 1.618em; }

/* line 41, ../scss/mixins.scss */
.single-product .summary .social:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both; }

/* line 4622, ../scss/style.scss */
.single-product .summary .social iframe {
  float: left; }

/* line 4628, ../scss/style.scss */
.single-product .summary .social span {
  position: relative;
  top: -1px; }

/* line 4634, ../scss/style.scss */
.single-product .summary .social span.st_plusone_button {
  top: -2px; }

/* line 4650, ../scss/style.scss */
.woocommerce_tabs ul.tabs, .woocommerce-tabs ul.tabs {
  list-style: none; }

/* line 41, ../scss/mixins.scss */
.woocommerce_tabs ul.tabs:after, .woocommerce-tabs ul.tabs:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both; }

/* line 4656, ../scss/style.scss */
.woocommerce_tabs ul.tabs li, .woocommerce-tabs ul.tabs li {
  width: 48%;
  float: left;
  clear: both;
  margin-bottom: 1em; }

/* line 4666, ../scss/style.scss */
.woocommerce_tabs ul.tabs li:nth-child(2n), .woocommerce-tabs ul.tabs li:nth-child(2n) {
  float: right;
  clear: none; }

/* line 4674, ../scss/style.scss */
.woocommerce_tabs ul.tabs li a, .woocommerce-tabs ul.tabs li a {
  display: block;
  font-weight: bold;
  padding: .53em 1em;
  border: 1px solid #666666; }

/* line 4684, ../scss/style.scss */
.woocommerce_tabs ul.tabs li a:before, .woocommerce-tabs ul.tabs li a:before {
  font-family: 'WebSymbolsRegular';
  display: inline-block;
  font-size: .857em;
  margin-right: .618em;
  font-weight: normal;
  content: "²";
  font-weight: normal; }

/* line 4698, ../scss/style.scss */
.woocommerce_tabs ul.tabs li.active a, .woocommerce-tabs ul.tabs li.active a {
  border-color: #444444; }

/* line 4708, ../scss/style.scss */
.woocommerce_tabs ul.tabs li.attributes_tab a:before, .woocommerce-tabs ul.tabs li.attributes_tab a:before {
  content: "³"; }

/* line 4718, ../scss/style.scss */
.woocommerce_tabs ul.tabs li.reviews_tab a:before, .woocommerce-tabs ul.tabs li.reviews_tab a:before {
  content: "e"; }

/* line 4730, ../scss/style.scss */
.woocommerce_tabs .panel, .woocommerce-tabs .panel {
  margin-bottom: 1.618em; }

/* 7.3 Cart */
/* line 4742, ../scss/style.scss */
table.cart .product-remove, table.cart .product-thumbnail, table.cart .product-price {
  position: absolute;
  left: -999em; }

/* line 4754, ../scss/style.scss */
table.cart td .backorder_notification {
  font-size: 0.8em; }

/* line 4774, ../scss/style.scss */
table.cart td.product-remove {
  padding-right: .5em;
  padding-left: .5em; }

/* line 4784, ../scss/style.scss */
table.cart td.actions .coupon {
  text-align: left;
  margin-bottom: 0; }

/* line 4792, ../scss/style.scss */
table.cart td.actions .coupon label {
  display: none; }

/* line 4800, ../scss/style.scss */
table.cart td.actions .coupon #shareyourcart_button {
  float: left;
  clear: left;
  margin-bottom: .618em; }

/* line 4810, ../scss/style.scss */
table.cart td.actions .coupon .input-text, table.cart td.actions .coupon .button {
  float: left; }

/* line 4820, ../scss/style.scss */
table.cart td.actions .coupon .button {
  float: right; }

/* line 4830, ../scss/style.scss */
table.cart td.actions .button, table.cart td.actions .checkout-button, table.cart td.actions .input-text {
  width: 100%;
  display: block;
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */
  margin-bottom: .53em !important; }

/* line 4844, ../scss/style.scss */
table.cart td.actions .checkout-button {
  margin-bottom: 0 !important; }

table tr th.product-subtotal,
table tr td.product-subtotal {
  text-align: right; }

#calc_shipping_state_field,
#calc_shipping_postcode_field {
  display: none !important; }

/* line 4856, ../scss/style.scss */
table.cart .product-quantity .plus, table.cart .product-quantity .minus {
  display: inline-block; }

/* line 4866, ../scss/style.scss */
table.cart img {
  width: 3.631em;
  height: auto; }

/* line 4878, ../scss/style.scss */
ul#shipping_method {
  list-style: none; }

/* line 4892, ../scss/style.scss */
.cross-sells ul.products li img {
  padding: 0;
  border: 0;
  background: 0; }

/* 7.4 Checkout */
/* line 4914, ../scss/style.scss */
.woocommerce-checkout .type-page h1:before {
  font-family: 'WebSymbolsRegular';
  display: inline-block;
  font-size: .857em;
  margin-right: .618em;
  font-weight: normal; }

/* line 4926, ../scss/style.scss */
.required {
  border: 0;
  color: #b85f56; }

div#order_review {
  font-size: 11px; }

/* line 4936, ../scss/style.scss */
.checkout #shiptobilling {
  float: none;
  width: 100%; }

/* line 4942, ../scss/style.scss */
.checkout #shiptobilling input[type="checkbox"] {
  float: left;
  margin-right: 1em; }

/* line 4952, ../scss/style.scss */
.checkout #order_review {
  -webkit-border-radius: 0.382em;
  border-radius: 0.382em;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; }

/* line 4960, ../scss/style.scss */
.checkout #order_review table {
  border-bottom: 0; }

/* line 4974, ../scss/style.scss */
.checkout #order_review table tr td:first-child, .checkout #order_review table tr th:first-child {
  padding-left: 1em; }

.col2-set::after {
  margin-bottom: 30px; }

.checkout #order_review #payment ul li {
  list-style: none;
  margin-bottom: 1em; }
  .checkout #order_review #payment ul li input[type="radio"] {
    margin: 1px 1em;
    float: left;
    box-shadow: 0 1px 0 0 white;
    -webkit-box-shadow: 0 1px 0 0 white; }
  .checkout #order_review #payment ul li label {
    font-weight: bold;
    margin-bottom: .618em;
    display: block; }
  .checkout #order_review #payment ul li label img {
    float: right;
    padding: 0;
    border: 0; }
  .checkout #order_review #payment ul li .payment_box {
    background: #fff;
    padding: 1em 10px;
    -webkit-border-radius: 0.382em;
    border-radius: 0.382em;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box; }
    .checkout #order_review #payment ul li .payment_box p:last-child {
      margin-bottom: 0; }
    .checkout #order_review #payment ul li .payment_box label {
      font-size: 1em; }

/* line 5058, ../scss/style.scss */
.checkout #order_review .form-row.place-order {
  width: 100%;
  margin-bottom: 0; }

/* line 41, ../scss/mixins.scss */
.checkout #order_review .form-row.place-order:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both; }

/* line 5066, ../scss/style.scss */
.checkout #order_review .form-row.place-order .button {
  margin-bottom: .618em;
  float: right; }

/* line 5076, ../scss/style.scss */
.checkout #order_review .form-row.place-order .form-row.terms {
  width: auto;
  float: right;
  margin-bottom: 0; }

/* line 5084, ../scss/style.scss */
.checkout #order_review .form-row.place-order .form-row.terms label {
  float: left;
  margin-right: .618em; }

/* line 5098, ../scss/style.scss */
.checkout .create-account {
  clear: both; }

/* line 5114, ../scss/style.scss */
ul.order_details {
  border: 1px solid #666666;
  list-style: none;
  padding-left: 1.618em;
  position: relative; }

/* line 41, ../scss/mixins.scss */
ul.order_details:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both; }

/* line 5126, ../scss/style.scss */
ul.order_details li {
  float: left;
  padding: 1em 2em 1em 0;
  margin-right: 2em;
  float: left;
  border-right: 1px solid #666666;
  text-transform: uppercase;
  font-size: .857em;
  color: #454545; }

/* line 5144, ../scss/style.scss */
ul.order_details li strong {
  display: block;
  text-transform: none;
  font-size: 1.387em;
  color: #121212; }

/* line 5156, ../scss/style.scss */
ul.order_details li:last-child {
  border-right: 0; }

/* line 5164, ../scss/style.scss */
ul.order_details:before {
  font-family: 'WebSymbolsRegular';
  display: inline-block;
  font-size: .857em;
  margin-left: .618em;
  font-weight: normal;
  content: "/";
  position: absolute;
  top: .327em;
  right: .618em;
  font-size: 2.244em;
  color: #84ac50; }

/* 7.5 Widgets */
/* line 5196, ../scss/style.scss */
.widget ul li .count {
  float: right; }

/* line 5208, ../scss/style.scss */
.widget ul.product_list_widget li {
  margin-bottom: .618em;
  padding-bottom: .618em;
  border-bottom: 1px dashed #666666; }

/* line 41, ../scss/mixins.scss */
.widget ul.product_list_widget li:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both; }

/* line 5218, ../scss/style.scss */
.widget ul.product_list_widget li a {
  display: block; }

/* line 5224, ../scss/style.scss */
.widget ul.product_list_widget li img {
  float: right;
  width: 2.244em;
  height: auto;
  margin-left: 1em;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; }

/* line 5238, ../scss/style.scss */
.widget ul.product_list_widget li del {
  opacity: 0.5; }

/* line 5244, ../scss/style.scss */
.widget ul.product_list_widget li ins {
  font-weight: bold;
  text-decoration: none;
  margin-left: .618em; }

/* line 5254, ../scss/style.scss */
.widget ul.product_list_widget li:last-child {
  border-bottom: 0; }

/* line 5272, ../scss/style.scss */
.widget_woo_subscribe #connect {
  background: none;
  border: 0;
  padding: 0; }

/* line 5294, ../scss/style.scss */
.widget_price_filter .price_slider {
  margin-bottom: 1em; }

/* line 5300, ../scss/style.scss */
.widget_price_filter .price_slider_amount {
  text-align: right;
  line-height: 2.4em; }

/* line 5308, ../scss/style.scss */
.widget_price_filter .price_slider_amount .button {
  float: left; }

/* line 5316, ../scss/style.scss */
.widget_price_filter .ui-slider {
  position: relative;
  text-align: left; }

/* line 5324, ../scss/style.scss */
.widget_price_filter .ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  width: 1em;
  height: 1em;
  -webkit-border-radius: 1em;
  border-radius: 1em;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  cursor: pointer;
  outline: none;
  background: #fff;
  border: 1px solid black;
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */ }

/* line 5348, ../scss/style.scss */
.widget_price_filter .ui-slider .ui-slider-handle:last-child {
  margin-left: -1em; }

/* line 5356, ../scss/style.scss */
.widget_price_filter .ui-slider .ui-slider-range {
  position: absolute;
  z-index: 1;
  font-size: .7em;
  display: block;
  border: 0;
  -webkit-transition: all ease-in-out 0.2s;
  -moz-transition: all ease-in-out 0.2s;
  -ms-transition: all ease-in-out 0.2s;
  -o-transition: all ease-in-out 0.2s;
  transition: all ease-in-out 0.2s;
  background: black;
  -webkit-border-radius: 1em;
  border-radius: 1em;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; }

/* line 5376, ../scss/style.scss */
.widget_price_filter .price_slider_wrapper .ui-widget-content {
  -webkit-border-radius: 1em;
  border-radius: 1em;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  background: #666666; }

/* line 5384, ../scss/style.scss */
.widget_price_filter .ui-slider-horizontal {
  height: 1em; }

/* line 5390, ../scss/style.scss */
.widget_price_filter .ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%; }

/* line 5398, ../scss/style.scss */
.widget_price_filter .ui-slider-horizontal .ui-slider-range-min {
  left: -1px; }

/* line 5406, ../scss/style.scss */
.widget_price_filter .ui-slider-horizontal .ui-slider-range-max {
  right: -1px; }

/* 7.6 Account */
/* line 5422, ../scss/style.scss */
#customer_login .col-1 h2:before, #customer_login .col-2 h2:before {
  font-family: 'WebSymbolsRegular';
  display: inline-block;
  font-size: .857em;
  margin-right: .618em;
  font-weight: normal; }

/* line 5436, ../scss/style.scss */
#customer_login .col-1 h2:before {
  content: "x"; }

/* line 5450, ../scss/style.scss */
#customer_login .col-2 h2:before {
  content: "U"; }

/* line 5460, ../scss/style.scss */
#customer_login .lost_password {
  display: block;
  margin-top: 1em; }

/* line 5472, ../scss/style.scss */
ul.digital-downloads li {
  list-style: none;
  padding-bottom: .618em; }

/* line 41, ../scss/mixins.scss */
ul.digital-downloads li:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both; }

/* line 5480, ../scss/style.scss */
ul.digital-downloads li .count {
  float: right; }

/* line 5488, ../scss/style.scss */
ul.digital-downloads li a:before {
  font-family: 'WebSymbolsRegular';
  display: inline-block;
  font-size: .857em;
  margin-right: .618em;
  font-weight: normal;
  content: ";"; }

/* line 5504, ../scss/style.scss */
table.my_account_orders .button {
  font-size: .857em; }

/* 7.7 Shortcodes */
/* line 5514, ../scss/style.scss */
.shortcode-sticky {
  background-color: #f6eeca;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f6eeca), to(#faf5e0));
  /* Saf4+, Chrome */
  background-image: -webkit-linear-gradient(top, #f6eeca, #faf5e0);
  /* Chrome 10+, Saf5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(top, #f6eeca, #faf5e0);
  /* FF3.6+ */
  background-image: -ms-linear-gradient(top, #f6eeca, #faf5e0);
  /* IE10 */
  background-image: -o-linear-gradient(top, #f6eeca, #faf5e0);
  /* Opera 11.10+ */
  background-image: linear-gradient(to bottom, #f6eeca, #faf5e0);
  padding: 1em 1.618em 2.65em 1.618em;
  border-top: 1.618em solid #f2e8b5;
  border-bottom: 1px solid #fcf7f2;
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.6);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  -webkit-transform: rotate(1deg);
  /* Saf3.1+, Chrome */
  -moz-transform: rotate(1deg);
  /* FF3.5+ */
  -ms-transform: rotate(1deg);
  /* IE9 */
  -o-transform: rotate(1deg);
  /* Opera 10.5 */
  transform: rotate(1deg);
  zoom: 1;
  font-family: "museo-sans-rounded", Helvetica, sans-serif;
  margin: 1.618em;
  font-size: 1.142em;
  color: #554444;
  max-width: 22em; }

/* line 5542, ../scss/style.scss */
.shortcode-sticky a {
  padding: 0 6px;
  background: rgba(255, 255, 0, 0.1);
  color: #443333; }

/* line 5554, ../scss/style.scss */
.shortcode-sticky.left {
  float: left; }

/* line 5562, ../scss/style.scss */
.shortcode-sticky.right {
  float: right; }

/* line 5572, ../scss/style.scss */
.shortcode-sale {
  padding: .618em 1em;
  background: black;
  color: #fff;
  margin-bottom: 2.618em;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 0.202em;
  border-radius: 0.202em;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  clear: both;
  font-weight: bold;
  text-align: center; }

/* line 5594, ../scss/style.scss */
.shortcode-sale:before {
  font-family: 'WebSymbolsRegular';
  display: inline-block;
  font-size: .857em;
  margin-right: .618em;
  font-weight: normal;
  content: "R";
  float: left; }

/* line 5604, ../scss/style.scss */
.shortcode-sale:after {
  font-family: 'WebSymbolsRegular';
  display: inline-block;
  font-size: .857em;
  margin-left: .618em;
  font-weight: normal;
  content: "R";
  float: right; }

/* line 5620, ../scss/style.scss */
.shortcode-sale a {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  padding: 3px 12px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  margin: 0 6px;
  -webkit-transition: all ease-in-out 0.2s;
  -moz-transition: all ease-in-out 0.2s;
  -ms-transition: all ease-in-out 0.2s;
  -o-transition: all ease-in-out 0.2s;
  transition: all ease-in-out 0.2s; }

/* line 5636, ../scss/style.scss */
.shortcode-sale a:hover {
  background: rgba(255, 255, 255, 0.2);
  color: #fff; }

/*-------------------------------------------------------------------------------------------*/
/* 8. MISC */
/*-------------------------------------------------------------------------------------------*/
/* 8.1 Forms */
/* line 5664, ../scss/style.scss */
.form-row label {
  display: block; }

/* line 5670, ../scss/style.scss */
.form-row .input-text {
  width: 100%; }

/* line 5680, ../scss/style.scss */
input[type=text], input.input-text, textarea, input.txt, input[type=tel], input[type=email] {
  padding: 3px 6px;
  border: 1px solid #121212;
  font-size: 1em;
  /* Make inputs the same size as normal text */
  line-height: 1.618em;
  font-family: inherit;
  /* Make inputs use the correct typeface instead of the browser default */
  outline: none;
  width: 205px;
  color: #121212;
  background: transparent;
  -webkit-appearance: none; }

/* line 5712, ../scss/style.scss */
input[type=text]:focus, input.input-text:focus, textarea:focus, input.txt:focus, input[type=tel]:focus, input[type=email]:focus {
  background: transparent;
  -webkit-box-shadow: 0 0 0 0.327em fade(black, 10%), 0 0 0.202em fade(black, 40%);
  box-shadow: 0 0 0 0.327em fade(black, 10%), 0 0 0.202em fade(black, 40%); }

/* line 5762, ../scss/style.scss */
/* line 5850, ../scss/style.scss */
.mc-field-group {
  display: inline-block;
  margin: 0; }

/* line 5864, ../scss/style.scss */
form {
  margin-bottom: 1.5em; }
  form .msg {
    margin: 0 0 20px 0; }

/* line 5880, ../scss/style.scss */
input, textarea {
  border-radius: 0; }

table.variations {
  margin-bottom: 12px; }

.woocommerce-variation-price {
  margin: 0px 0 21px 0; }

#pa_size {
  margin-right: 10px;
  float: left; }

label[for=pa_size]:after {
  content: ": "; }

table.variations td.value {
  padding-left: 0; }

a.reset_variations {
  color: black;
  background-color: white;
  border: 1px solid black;
  display: inline-block;
  padding: 0 10px;
  line-height: 17px;
  height: 17px;
  border-radius: 12px;
  float: left;
  text-transform: lowercase;
  font-size: 12px;
  transition: all .2s ease-in-out;
  opacity: .33; }
  a.reset_variations:hover {
    color: white;
    background-color: black;
    opacity: 1; }

/* line 5890, ../scss/style.scss */
.qov {
  background: white;
  width: 100px;
  height: 16px;
  background-position: -441px -226px;
  margin: 0 auto; }

/* line 5906, ../scss/style.scss */
#product_nav {
  width: 920px;
  background: #121212;
  text-align: center;
  padding-top: 1px;
  margin: 0 auto 20px auto; }

/* line 5920, ../scss/style.scss */
#product_nav li {
  display: inline-block;
  margin: 0;
  padding: 0 43px;
  list-style: none;
  position: relative; }

/* line 5932, ../scss/style.scss */
#product_nav li a {
  color: white;
  font-weight: 500;
  font-size: 1em;
  line-height: 1.5em; }

#product_nav.product_cat-cushions li.cushions:before,
#product_nav.product_cat-home li.home:before,
#product_nav.product_cat-handbags li.bags:before,
#product_nav.product_cat-rugs-s li.rugs-s:before,
#product_nav.product_cat-rugs-l li.rugs-l:before,
#product_nav.product_cat-plaids-and-blankets li.plaids-and-blankets:before,
#product_nav.product_cat-scarves li.scarves:before,
#product_nav li:hover:before,
.term-rugs-s #product_nav li.rugs-s:before,
.term-rugs-l #product_nav li.rugs-l:before,
.term-plaids-and-blankets #product_nav li.plaids-and-blankets:before,
.term-scarves #product_nav li.scarves:before,
.term-bags #product_nav li.bags:before {
  content: " ";
  display: block;
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  top: 6px;
  left: 32px;
  background: white; }

/* line 5950, ../scss/style.scss */
.entry_points {
  margin-top: 20px; }

/* line 41, ../scss/mixins.scss */
.entry_points:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both; }

/* line 5958, ../scss/style.scss */
.entry_points .col {
  float: left;
  margin: 0 20px 20px 0;
  text-align: center;
  font-weight: 300;
  font-size: 1.0833333333em; }

/* line 5972, ../scss/style.scss */
.entry_points .col .linker {
  padding-top: 100px;
  display: block;
  line-height: 32px; }

/* line 5984, ../scss/style.scss */
.entry_points .col p {
  text-align: center; }

/* line 5992, ../scss/style.scss */
.entry_points .col p {
  padding: 0px; }

/* line 5996, ../scss/style.scss */
.entry_points .col p.queondavos {
  padding-top: 45px; }

/* line 6006, ../scss/style.scss */
.entry_points .col.one_col {
  width: 168px;
  height: 232px; }

/* line 6016, ../scss/style.scss */
.entry_points .col.two_col {
  width: 356px;
  height: 232px; }

/* line 6026, ../scss/style.scss */
.entry_points .col.last {
  margin-right: 0; }

/* line 6052, ../scss/style.scss */
a.button,
a.comment-reply-link,
#commentform #submit,
.submit,
input[type=submit],
input.button,
button.button {
  display: inline-block;
  color: white;
  text-align: center;
  text-decoration: none;
  /* Make inputs the same size as normal text */
  font-family: inherit;
  /* Make inputs use the correct typeface instead of the browser default */
  cursor: pointer;
  /* Inputs need pointers! */
  overflow: visible;
  /* IE fix */
  width: 246px !important;
  height: 38px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /* IE fix */
  line-height: 20px;
  margin: 0 0 0 0;
  padding: 10px;
  border: 1px solid #121212;
  position: relative;
  top: 1px;
  -webkit-font-smoothing: subpixel-antialiased;
  font-weight: normal;
  text-transform: uppercase;
  border: none;
  background: #121212; }

/* line 6108, ../scss/style.scss */
a.button.alt, a.button.checkout,
a.comment-reply-link.alt,
a.comment-reply-link.checkout,
#commentform #submit.alt,
#commentform #submit.checkout,
.submit.alt,
.submit.checkout,
input[type=submit].alt,
input[type=submit].checkout,
input.button.alt,
input.button.checkout,
button.button.alt,
button.button.checkout {
  background-color: #111111;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#111111), to(black));
  /* Saf4+, Chrome */
  background-image: -webkit-linear-gradient(top, #111111, black);
  /* Chrome 10+, Saf5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(top, #111111, black);
  /* FF3.6+ */
  background-image: -ms-linear-gradient(top, #111111, black);
  /* IE10 */
  background-image: -o-linear-gradient(top, #111111, black);
  /* Opera 11.10+ */
  background-image: linear-gradient(to bottom, #111111, #000000);
  text-shadow: 0 1px 0 black;
  border: 1px solid black; }

/* line 6136, ../scss/style.scss */
a.button.alt:hover, a.button.checkout:hover,
a.comment-reply-link.alt:hover,
a.comment-reply-link.checkout:hover,
#commentform #submit.alt:hover,
#commentform #submit.checkout:hover,
.submit.alt:hover,
.submit.checkout:hover,
input[type=submit].alt:hover,
input[type=submit].checkout:hover,
input.button.alt:hover,
input.button.checkout:hover,
button.button.alt:hover,
button.button.checkout:hover {
  background-color: #111111;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#111111), to(black));
  /* Saf4+, Chrome */
  background-image: -webkit-linear-gradient(top, #111111, black);
  /* Chrome 10+, Saf5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(top, #111111, black);
  /* FF3.6+ */
  background-image: -ms-linear-gradient(top, #111111, black);
  /* IE10 */
  background-image: -o-linear-gradient(top, #111111, black);
  /* Opera 11.10+ */
  background-image: linear-gradient(to bottom, #111111, #000000); }

/* line 6142, ../scss/style.scss */
a.button.alt:active, a.button.checkout:active,
a.comment-reply-link.alt:active,
a.comment-reply-link.checkout:active,
#commentform #submit.alt:active,
#commentform #submit.checkout:active,
.submit.alt:active,
.submit.checkout:active,
input[type=submit].alt:active,
input[type=submit].checkout:active,
input.button.alt:active,
input.button.checkout:active,
button.button.alt:active,
button.button.checkout:active {
  background-color: black;
  background-image: -webkit-gradient(linear, left top, left bottom, from(black), to(#111111));
  /* Saf4+, Chrome */
  background-image: -webkit-linear-gradient(top, black, #111111);
  /* Chrome 10+, Saf5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(top, black, #111111);
  /* FF3.6+ */
  background-image: -ms-linear-gradient(top, black, #111111);
  /* IE10 */
  background-image: -o-linear-gradient(top, black, #111111);
  /* Opera 11.10+ */
  background-image: linear-gradient(to bottom, #000000, #111111); }

/* line 6152, ../scss/style.scss */
a.button.large,
a.comment-reply-link.large,
#commentform #submit.large,
.submit.large,
input[type=submit].large,
input.button.large,
button.button.large {
  font-size: 1.387em; }

/* line 6154, ../scss/style.scss */
a.button.small,
a.comment-reply-link.small,
#commentform #submit.small,
.submit.small,
input[type=submit].small,
input.button.small,
button.button.small {
  font-size: .857em; }

/* line 6172, ../scss/style.scss */
a.remove {
  font-family: 'WebSymbolsRegular'; }

/* 8.2 Gravity forms */
/* line 6184, ../scss/style.scss */
ul.gform_fields li {
  list-style: none; }

/* line 6194, ../scss/style.scss */
.gfield {
  margin-bottom: 1.618em; }

/* line 6202, ../scss/style.scss */
.gfield_label {
  width: 100%;
  font-weight: bold;
  margin-bottom: .618em;
  display: block; }

/* line 6216, ../scss/style.scss */
.gfield_required {
  color: red; }

/* line 6224, ../scss/style.scss */
.ginput_container {
  margin-bottom: 1.618em; }

/* line 41, ../scss/mixins.scss */
.ginput_container:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both; }

/* line 6232, ../scss/style.scss */
.ginput_container label {
  display: block; }

/* line 6240, ../scss/style.scss */
.ginput_container input[type=text], .ginput_container textarea {
  width: 100%; }

/* line 6248, ../scss/style.scss */
.ginput_container .ginput_left, .ginput_container .ginput_right, .ginput_container .ginput_full {
  margin-bottom: 1em;
  display: block; }

/* line 6254, ../scss/style.scss */
.ginput_container .ginput_left label, .ginput_container .ginput_right label, .ginput_container .ginput_full label {
  font-size: .875em; }

/* line 6264, ../scss/style.scss */
.ginput_container .ginput_left, .ginput_container .ginput_right {
  width: 48%;
  float: left;
  clear: left; }

/* line 6276, ../scss/style.scss */
.ginput_container .ginput_right {
  float: right;
  clear: none; }

/* line 6288, ../scss/style.scss */
.ginput_container .gfield_checkbox input, .ginput_container .gfield_radio input {
  float: left; }

/* line 6300, ../scss/style.scss */
img.ui-datepicker-trigger {
  border: 0;
  padding: 0; }

/* 8.3 IE specific styling */
/* line 6316, ../scss/style.scss */
.ie8 .widget_woo_blogauthorinfo .avatar {
  width: auto; }

/* line 6320, ../scss/style.scss */
.ie8 #top select {
  display: none !important; }

table.cart img {
  width: 90px;
  height: auto; }

/*

The styles in this stylesheet only apply to resolutions 768px and up



CALCULATING DIMENSIONS:

----------------------

The layout is fluid. The sote wrapper (.col-full) has a fixed pixel max-width.

All subsequent dimensions are calculated based on that fixed pixel width, using the formula: target / context = result

Credit - http://www.alistapart.com/articles/fluidgrids/



-----

INDEX

-----



1. Global dropdown styles

2. Top Navigation

3. Main navigation dropdown menus

 -3.1 General dropdown menus

4. General layout

5. WOOCOMMERCE

-5.1 Messages

-5.2 Products

  -5.2.1 Single Product

-5.3 Cart

-5.4 Checkout

-5.5 Widgets

-5.6 Account

-5.7 Shortcodes

6. Footer widgets

7. IE Fixes


*/
/*----------------------*/
@media only screen {
  /* line 81, ../scss/layout.scss */
  html.boxed {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAG0lEQVQIW2NkYGAwBuKzQAwGjDAGNgGwSgwVAFCGAgUGpiTrAAAAAElFTkSuQmCC) #eeeeee; }
  .pin_it_iframe_widget {
    display: none; }
  ul.nav li.wpmenucart-display-right {
    float: left !important; }
  /* line 85, ../scss/layout.scss */
  html.boxed body {
    margin: 2.618em;
    box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.1); }
  /* line 95, ../scss/layout.scss */
  html,
  body {
    padding: 0px;
    min-height: 100%;
    margin: 0; }
  /* line 103, ../scss/layout.scss */
  table {
    font-size: 1em !important; }
  /* 1. GLOBAL DROPDOWN STYLES (these are purely for the dropdown layout and you should only edit the width of the dropdowns) */
  /* line 113, ../scss/layout.scss */
  ul.main-nav {
    position: relative;
    margin-bottom: 0;
    /* LEVEL 2 */
    /* LEVEL 3 */ }
  /* line 121, ../scss/layout.scss */
  ul.nav li {
    position: relative;
    float: left;
    zoom: 1;
    list-style: none; }
  ul.nav li.usesSprite {
    margin-left: 5px; }
  ul.main-nav li.instagram {
    margin-left: 24px; }
  ul.nav li.instagram {
    margin-top: -1px;
    background-position: -30px -372px; }
  ul.nav li.twitter {
    margin-top: -1px;
    background-position: -95px -372px; }
  ul.nav li.pinterest {
    margin-top: -1px;
    background-position: -57px -372px; }
  ul.nav li.facebook {
    margin-top: -1px;
    background-position: -485px -58px; }
  ul.nav li.instagram a,
  ul.nav li.twitter a,
  ul.nav li.pinterest a,
  ul.nav li.facebook a {
    width: 20px;
    padding: 0; }
  #navigation ul.nav li.twitter a:hover > span,
  #navigation ul.nav li.pinterest a:hover > span,
  #navigation ul.nav li.instagram a:hover > span,
  #navigation ul.nav li.facebook a:hover > span {
    border-bottom: 0px solid transparent; }
  /* line 145, ../scss/layout.scss */
  ul.nav li a {
    display: block;
    padding: 0 30px;
    text-transform: uppercase; }
  /* line 165, ../scss/layout.scss */
  ul.nav ul {
    width: 15.379em;
    visibility: hidden;
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 9999;
    margin: 0; }
  /* line 183, ../scss/layout.scss */
  ul.nav ul li {
    float: none; }
  /* line 187, ../scss/layout.scss */
  ul.nav ul li a {
    width: 100%;
    display: inline-block;
    -webkit-box-sizing: border-box;
    /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;
    /* Firefox, other Gecko */
    box-sizing: border-box;
    /* Opera/IE 8+ */ }
  /* line 207, ../scss/layout.scss */
  ul.nav ul ul {
    left: 100%;
    top: 0; }
  /* line 223, ../scss/layout.scss */
  ul.nav li:hover > ul {
    visibility: visible;
    display: block; }
  /* 2. TOP NAVIGATION (Add top navigation presentational styles here) */
  /* line 237, ../scss/layout.scss */
  #top {
    background: white;
    margin: 0 -3.631em;
    padding: 0 3.631em; }
  /* line 245, ../scss/layout.scss */
  #top #top-nav {
    display: block; }
  /* line 251, ../scss/layout.scss */
  #top ul.nav {
    font-size: .857em;
    /* LEVEL 2 */
    /* LEVEL 3 */ }
  /* line 257, ../scss/layout.scss */
  #top ul.nav > li a {
    padding: 1.387em 1em;
    color: #454545; }
  /* line 263, ../scss/layout.scss */
  #top ul.nav > li a:hover {
    background: white;
    text-decoration: none;
    color: #121212; }
  /* line 275, ../scss/layout.scss */
  #top ul.nav > li:hover {
    background: white; }
  /* line 285, ../scss/layout.scss */
  #top ul.nav ul {
    background: white; }
  /* line 293, ../scss/layout.scss */
  #top ul.nav ul li a {
    padding: .53em 1em; }
  /* line 315, ../scss/layout.scss */
  #top .wc-nav {
    width: auto;
    float: right; }
  /* line 321, ../scss/layout.scss */
  #top .wc-nav li.checkout {
    display: inline-block; }
  /* line 327, ../scss/layout.scss */
  #top .wc-nav li.checkout a:before {
    font-family: 'WebSymbolsRegular';
    display: inline-block;
    font-size: .857em;
    margin-right: .618em;
    font-weight: normal;
    content: ")";
    font-weight: normal; }
  /* line 341, ../scss/layout.scss */
  #top .wc-nav li.search {
    width: auto; }
  /* line 345, ../scss/layout.scss */
  #top .wc-nav li.search form {
    margin-bottom: 0;
    padding-top: 1.1em; }
  /* line 353, ../scss/layout.scss */
  #top .wc-nav li.search label {
    display: none; }
  /* line 359, ../scss/layout.scss */
  #top .wc-nav li.search .button {
    position: absolute;
    top: 0;
    left: -999em; }
  /* line 369, ../scss/layout.scss */
  #top .wc-nav li.search:hover {
    background: none; }
  /* line 377, ../scss/layout.scss */
  #top .wc-nav li.cart {
    width: auto;
    text-align: right; }
  /* line 383, ../scss/layout.scss */
  #top .wc-nav li.cart a {
    padding-top: 1.387em;
    padding-left: 1em; }
  /* line 389, ../scss/layout.scss */
  #top .wc-nav li.cart a .contents {
    padding: .2em .618em;
    background: #fff;
    -webkit-border-radius: 1em;
    border-radius: 1em;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    margin-left: 1em;
    display: inline; }
  /* 3. MAIN NAVIGATION DROPDOWN MENUS (Add main navigation presentational styles here) */
  /* line 415, ../scss/layout.scss */
  #header #navigation {
    display: block !important; }
  /* line 419, ../scss/layout.scss */
  #header #navigation ul.nav {
    float: right;
    /* LEVEL 2 */
    /* LEVEL 3 */ }
  /* line 425, ../scss/layout.scss */
  #header #navigation ul.nav > li a {
    position: relative; }
  /* line 461, ../scss/layout.scss */
  #header #navigation ul.nav > li.parent a {
    -webkit-border-bottom-left-radius: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box; }
  /* line 467, ../scss/layout.scss */
  #header #navigation ul.nav > li.parent a:after {
    font-family: 'WebSymbolsRegular';
    display: inline-block;
    font-size: .857em;
    margin-left: .618em;
    font-weight: normal;
    content: ";";
    color: #898989;
    font-weight: normal; }
  /* line 481, ../scss/layout.scss */
  #header #navigation ul.nav > li.parent a:hover:before {
    content: "";
    display: block;
    height: 2px;
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 99999; }
  /* line 509, ../scss/layout.scss */
  #header #navigation ul.nav > li.parent:hover a:before {
    content: "";
    display: block;
    height: 2px;
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 99999; }
  /* line 531, ../scss/layout.scss */
  #header #navigation ul.nav > li.parent:hover a:after {
    color: black; }
  /* line 547, ../scss/layout.scss */
  #header #navigation ul.nav > li.parent ul li a:after {
    visibility: hidden; }
  /* line 559, ../scss/layout.scss */
  #header #navigation ul.nav > li.parent ul li.parent a:after {
    visibility: visible;
    content: "]";
    float: right; }
  /* line 575, ../scss/layout.scss */
  #header #navigation ul.nav > li.parent ul li.parent ul li a:after {
    visibility: hidden; }
  /* line 585, ../scss/layout.scss */
  #header #navigation ul.nav > li.parent ul li.parent ul li.parent a:after {
    visibility: visible;
    content: "]"; }
  /* line 599, ../scss/layout.scss */
  #header #navigation ul.nav > li.parent ul li.parent ul li.parent ul li a:after {
    visibility: hidden; }
  /* line 625, ../scss/layout.scss */
  #header #navigation ul.nav ul {
    border: 1px solid #666666;
    margin-top: -1px;
    padding: .372em 0;
    box-shadow: 0 0.202em 0 0 fade(#666666, 40%);
    -webkit-box-shadow: 0 0.202em 0 0 fade(#666666, 40%);
    background: #fff; }
  /* line 639, ../scss/layout.scss */
  #header #navigation ul.nav ul li a {
    border: 0;
    background: #fff; }
  /* line 653, ../scss/layout.scss */
  #header #navigation ul.nav ul ul {
    left: 100%;
    top: -.372em; }
  /* line 671, ../scss/layout.scss */
  h3.nav-toggle {
    display: none; }
  /* 4. GENERAL LAYOUT */
  /* line 683, ../scss/layout.scss */
  #breadcrumbs {
    display: block; }
  /* line 693, ../scss/layout.scss */
  .homepage-banner {
    position: relative;
    max-width: 95.949em;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3.631em;
    display: block; }
  /* line 707, ../scss/layout.scss */
  .homepage-banner img {
    margin-bottom: 0;
    width: 100%;
    position: relative; }
  /* line 719, ../scss/layout.scss */
  .homepage-banner h1, .homepage-banner .description {
    z-index: 55; }
  /* line 725, ../scss/layout.scss */
  .homepage-banner h1 {
    position: absolute;
    top: 30%;
    left: 20%;
    right: 20%;
    color: #fff;
    text-align: left;
    font-size: 3.631em; }
  /* line 741, ../scss/layout.scss */
  .homepage-banner h1 span {
    -webkit-border-radius: 0.236em;
    border-radius: 0.236em;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.7); }
  /* line 755, ../scss/layout.scss */
  .homepage-banner .description {
    position: absolute;
    top: 50%;
    left: 25%;
    right: 20%;
    color: #fff;
    font-size: 1.618em;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.7);
    font-weight: bold;
    -webkit-border-radius: 0.236em;
    border-radius: 0.236em;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box; }
  /* line 779, ../scss/layout.scss */
  .homepage-banner .description p {
    text-align: left; }
  /* line 783, ../scss/layout.scss */
  .homepage-banner .description p:last-child {
    margin-bottom: 0; }
  /* line 793, ../scss/layout.scss */
  .homepage-banner:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 50;
    bottom: 0;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAG0lEQVQIW2NkYGAwBuKzQAwGjDAGNgGwSgwVAFCGAgUGpiTrAAAAAElFTkSuQmCC); }
  /* line 821, ../scss/layout.scss */
  .home #content.with-banner {
    border-top: 4px double #666666;
    padding-top: 3.631em; }
  /* line 837, ../scss/layout.scss */
  .archive-header h1 {
    float: left; }
  /* line 843, ../scss/layout.scss */
  .archive-header .archive-rss {
    float: right;
    padding-top: 1em; }
  /* line 851, ../scss/layout.scss */
  .archive-header .archive-rss a:before {
    font-family: 'WebSymbolsRegular';
    display: inline-block;
    font-size: .857em;
    margin-right: .618em;
    font-weight: normal;
    content: "r";
    color: orange; }
  /* line 41, ../scss/mixins.scss */
  .type-post:after {
    content: "\0020";
    display: block;
    height: 0;
    overflow: hidden;
    clear: both; }
  /* line 871, ../scss/layout.scss */
  .type-post .meta {
    display: block;
    width: 11%;
    float: left; }
  /* line 879, ../scss/layout.scss */
  .type-post .meta .day, .type-post .meta .month, .type-post .meta .year {
    display: block;
    text-align: center;
    width: 65.4%;
    margin: 0 auto;
    background: #fff; }
  /* line 893, ../scss/layout.scss */
  .type-post .meta .month {
    text-transform: uppercase;
    font-weight: bold;
    background: black;
    border-top: 0.202em solid black;
    color: #fff;
    font-size: .857em;
    padding: .202em 0; }
  /* line 911, ../scss/layout.scss */
  .type-post .meta .day {
    font-size: 2em;
    line-height: 1.387;
    border: 1px solid #666666;
    border-width: 0 1px;
    font-weight: bold; }
  /* line 925, ../scss/layout.scss */
  .type-post .meta .year {
    border: 1px solid #666666;
    border-top: 0;
    -webkit-border-bottom-left-radius: 0.327em;
    -webkit-border-bottom-right-radius: 0.327em;
    border-bottom-left-radius: 0.327em;
    border-bottom-right-radius: 0.327em;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    margin-bottom: 1em;
    font-size: .857em;
    padding-bottom: .53em;
    color: #565656; }
  /* line 943, ../scss/layout.scss */
  .type-post .meta img {
    padding: 0;
    border: 0;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    margin-bottom: 1em;
    display: block;
    height: auto; }
  /* line 961, ../scss/layout.scss */
  .type-post .post-content {
    width: 82.7%;
    float: right; }
  /* line 977, ../scss/layout.scss */
  .single .type-post p:first-child {
    font-size: 1.387em; }
  /* line 989, ../scss/layout.scss */
  #post-entries {
    width: 82.7%;
    float: right;
    -webkit-box-sizing: border-box;
    /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;
    /* Firefox, other Gecko */
    box-sizing: border-box;
    /* Opera/IE 8+ */ }
  /* line 1001, ../scss/layout.scss */
  #comments, #respond {
    clear: both; }
  /* line 1015, ../scss/layout.scss */
  #comments .commentlist li.comment ul.children {
    float: right;
    width: 82.7%;
    clear: right;
    padding-top: 2.618em; }
  /* line 1039, ../scss/layout.scss */
  .single-post #comments h2:first-child {
    width: 82.7%;
    float: right; }
  /* line 1053, ../scss/layout.scss */
  #respond {
    width: 82.7%;
    float: right; }
  /* line 1065, ../scss/layout.scss */
  #fancybox-outer #respond {
    width: 100%;
    float: none; }
  /* line 1071, ../scss/layout.scss */
  #fancybox-outer #respond textarea {
    width: 90%; }
  /* line 1085, ../scss/layout.scss */
  .col2-set {
    clear: both; }
  /* line 41, ../scss/mixins.scss */
  .col2-set:after {
    content: "\0020";
    display: block;
    height: 0;
    overflow: hidden;
    clear: both; }
  /* line 1093, ../scss/layout.scss */
  .col2-set .col-1 {
    width: 48%;
    float: left; }
  /* line 1103, ../scss/layout.scss */
  .col2-set .col-2 {
    width: 48%;
    float: right; }
  /* line 1115, ../scss/layout.scss */
  .form-row {
    width: 48%;
    float: left;
    margin-bottom: 10px; }
  /* line 1125, ../scss/layout.scss */
  .form-row label {
    display: block;
    padding: .206em 0; }
  /* line 1135, ../scss/layout.scss */
  .form-row .input-text, .form-row select {
    width: 100%;
    *width: 90%;
    -webkit-box-sizing: border-box;
    /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;
    /* Firefox, other Gecko */
    box-sizing: border-box;
    /* Opera/IE 8+ */ }
  /* line 1147, ../scss/layout.scss */
  .form-row input[type=checkbox] {
    float: left; }
  /* line 1155, ../scss/layout.scss */
  .form-row.notes {
    width: 100%;
    float: none; }
  /* line 1163, ../scss/layout.scss */
  .form-row.notes textarea {
    width: 100%;
    *width: 90%; }
  /* line 1175, ../scss/layout.scss */
  .form-row.notes + h3 {
    clear: both; }
  .woocommerce-billing-fields,
  .woocommerce-shipping-fields {
    margin: 0 -10px; }
    .woocommerce-billing-fields h3,
    .woocommerce-billing-fields .form-row,
    .woocommerce-shipping-fields h3,
    .woocommerce-shipping-fields .form-row {
      padding: 0 10px;
      box-sizing: border-box; }
  #payment.woocommerce-checkout-payment,
  #order_review_heading {
    margin-top: 80px; }
  .form-row-first,
  .form-row-last {
    float: left;
    width: 50%; }
  .form-row-wide {
    float: left;
    width: 100%; }
  .payment_box.payment_method_Striper .form-row {
    padding: 0; }
    .payment_box.payment_method_Striper .form-row.form-row-last, .payment_box.payment_method_Striper .form-row.form-row-first {
      width: 20%;
      padding-right: 10px; }
  /* line 1201, ../scss/layout.scss */
  .form-row-wide {
    float: left;
    width: 100%;
    clear: both; }
  /* line 1213, ../scss/layout.scss */
  header.title {
    overflow: hidden;
    zoom: 1; }
  /* line 1221, ../scss/layout.scss */
  header.title h1, header.title h2, header.title h3, header.title h4, header.title h5 {
    float: left; }
  /* line 1229, ../scss/layout.scss */
  header.title a.edit {
    float: right;
    font-weight: bold;
    line-height: 2.4em; }
  /* line 1237, ../scss/layout.scss */
  header.title a.edit:before {
    font-family: 'WebSymbolsRegular';
    display: inline-block;
    font-size: .857em;
    margin-right: .618em;
    font-weight: normal;
    content: "S"; }
  /* line 41, ../scss/mixins.scss */
  /* line 1257, ../scss/layout.scss */
  #header hgroup {
    float: left; }
  /* line 1263, ../scss/layout.scss */
  #header #navigation {
    float: right;
    clear: none;
    border: 0;
    background: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: auto; }
  /* line 1283, ../scss/layout.scss */
  #main.fullwidth, .layout-full #main, .col-full, .no-sidebar #main {
    width: 920px;
    margin: 0 auto; }
  /*
  
  	#main {
  
  		width: 69.2%;
  
  	}
  
  	*/
  /* line 1303, ../scss/layout.scss */
  #sidebar {
    margin-top: 0;
    width: 22.05%;
    font-size: .857em; }
  /* line 41, ../scss/mixins.scss */
  .entry:after {
    content: "\0020";
    display: block;
    height: 0;
    overflow: hidden;
    clear: both; }
  /* line 1319, ../scss/layout.scss */
  .entry img {
    max-width: 100%; }
  /* line 1325, ../scss/layout.scss */
  .layout-full .entry img {
    max-width: 100%; }
  /* line 1333, ../scss/layout.scss */
  .layout-right-content #main {
    float: right; }
  /* line 1339, ../scss/layout.scss */
  .layout-right-content #sidebar {
    float: left; }
  /* line 1347, ../scss/layout.scss */
  .layout-full #main {
    width: 100%; }
  /* line 1353, ../scss/layout.scss */
  .col-left {
    float: left; }
  /* line 1359, ../scss/layout.scss */
  .col-right {
    float: right; }
  /* line 1369, ../scss/layout.scss */
  .page-template-template-contact-php .location-twitter .col-left {
    float: left; }
  /* line 1375, ../scss/layout.scss */
  .page-template-template-contact-php .location-twitter #office-location {
    width: 48%;
    margin: 0; }
  /* line 1383, ../scss/layout.scss */
  .page-template-template-contact-php .location-twitter .contact-social {
    float: right;
    width: 48%; }
  /* line 1389, ../scss/layout.scss */
  .page-template-template-contact-php .location-twitter .contact-social #twitter {
    margin: 0; }
  /* line 1403, ../scss/layout.scss */
  .footer-wrap {
    margin: 0 -3.631em;
    padding: 0 3.631em; }
  p.product.woocommerce {
    border: none !important;
    padding: 0px !important; }
  p.product.woocommerce span {
    display: none; }
  .logo_support {
    text-align: center; }
  .logo_support strong,
  .logo_support img {
    float: none;
    display: inline-block;
    position: relative;
    top: 10px; }
  .logo_support img {
    margin-top: 75px;
    margin-left: 10px;
    position: relative;
    top: 15px; }
  /*-------------------------------------------------------------------------------------------*/
  /* 7. WOOCOMMERCE */
  /*-------------------------------------------------------------------------------------------*/
  /* 7.1 Messages */
  /* 7.2 Products */
  /* line 1431, ../scss/layout.scss */
  ul.products {
    margin: 0 -10px; }
    ul.products li.product {
      position: relative;
      width: 450px;
      text-align: left;
      margin: 0 10px 20px 10px; }
  .term-cushions ul.products li.product:nth-child(2n),
  .term-handbags ul.products li.product:nth-child(2n) {
    clear: right; }
  .term-cushions ul.products li.product:nth-child(2n-1),
  .term-handbags ul.products li.product:nth-child(2n-1) {
    clear: left; }
  .woocommerce.columns-3 ul.products li.product,
  .term-plaids-and-blankets ul.products li.product,
  .term-rugs ul.products li.product,
  .term-rugs-l ul.products li.product,
  .term-rugs-s ul.products li.product,
  .term-laptopcases ul.products li.product,
  .term-blankets ul.products li.product,
  .term-carpets ul.products li.product,
  .term-scarves ul.products li.product {
    width: 293px;
    height: auto; }
  .woocommerce.columns-3 ul.products li.product:nth-child(3n),
  .term-plaids-and-blankets ul.products li.product:nth-child(3n),
  .term-rugs ul.products li.product:nth-child(3n),
  .term-rugs-l ul.products li.product:nth-child(3n),
  .term-rugs-s ul.products li.product:nth-child(3n),
  .term-laptopcases ul.products li.product:nth-child(3n),
  .term-blankets ul.products li.product:nth-child(3n),
  .term-carpets ul.products li.product:nth-child(3n),
  .term-scarves ul.products li.product:nth-child(3n) {
    clear: right; }
  .av-pre-order {
    position: absolute;
    bottom: 1px;
    left: 0;
    background-color: #fafafa;
    color: #121212;
    width: 100%; }
  p.pre-order-msg span {
    font-weight: bold; }
  .woocommerce.columns-3 ul.products li.product:nth-child(3n-2),
  .term-plaids-and-blankets ul.products li.product:nth-child(3n-2),
  .term-rugs ul.products li.product:nth-child(3n-2),
  .term-rugs-l ul.products li.product:nth-child(3n-2),
  .term-rugs-s ul.products li.product:nth-child(3n-2),
  .term-laptopcases ul.products li.product:nth-child(3n-2),
  .term-blankets ul.products li.product:nth-child(3n-2),
  .term-carpets ul.products li.product:nth-child(3n-2),
  .term-scarves ul.products li.product:nth-child(3n-2) {
    clear: left; }
  /*
    .woocommerce.columns-3 ul.products li.product:nth-child(2n - 1),
    .term-plaids-and-blankets ul.products li.product:nth-child(2n - 1),
    .term-rugs ul.products li.product:nth-child(2n - 1),
    .term-rugs-l ul.products li.product:nth-child(2n - 1),
    .term-rugs-s ul.products li.product:nth-child(2n - 1),
    .term-laptopcases ul.products li.product:nth-child(2n - 1),
    .term-blankets ul.products li.product:nth-child(2n - 1),
    .term-carpets ul.products li.product:nth-child(2n - 1),
    .term-scarves ul.products li.product:nth-child(2n - 1) {
      margin-right: 20px; }

    .woocommerce.columns-3 ul.products li.product:nth-child(3n),
    .term-plaids-and-blankets ul.products li.product:nth-child(3n),
    .term-rugs ul.products li.product:nth-child(3n),
    .term-rugs-l ul.products li.product:nth-child(3n),
    .term-rugs-s ul.products li.product:nth-child(3n),
    .term-laptopcases ul.products li.product:nth-child(3n),
    .term-blankets ul.products li.product:nth-child(3n),
    .term-carpets ul.products li.product:nth-child(3n),
    .term-scarves ul.products li.product:nth-child(3n) {
      margin-right: 0px; }

    */
  /* 7.2.1 Single Product */
  /* line 1501, ../scss/layout.scss */
  .single-product .images {
    width: 450px;
    float: left; }
  /* line 1509, ../scss/layout.scss */
  .single-product .summary {
    width: 246px;
    margin-left: 20px;
    float: right; }
  /* line 1519, ../scss/layout.scss */
  .single-product .woocommerce_tabs, .single-product .woocommerce-tabs {
    clear: both; }
  /* line 1525, ../scss/layout.scss */
  .single-product .related {
    clear: both; }
  /* line 1535, ../scss/layout.scss */
  .single-product .sale .summary h1 {
    width: 80%; }
  /* line 1549, ../scss/layout.scss */
  .woocommerce_tabs ul.tabs, .woocommerce-tabs ul.tabs {
    margin-bottom: 0;
    margin-left: 2.618em; }
  /* line 1555, ../scss/layout.scss */
  .woocommerce_tabs ul.tabs li, .woocommerce-tabs ul.tabs li {
    width: auto;
    float: left;
    clear: none;
    margin-bottom: 0; }
  /* line 1565, ../scss/layout.scss */
  .woocommerce_tabs ul.tabs li:nth-child(2n), .woocommerce-tabs ul.tabs li:nth-child(2n) {
    float: left;
    clear: none; }
  /* line 1573, ../scss/layout.scss */
  .woocommerce_tabs ul.tabs li a, .woocommerce-tabs ul.tabs li a {
    border-bottom: 0;
    background: fade(#666666, 50%);
    color: #121212; }
  /* line 1581, ../scss/layout.scss */
  .woocommerce_tabs ul.tabs li a:hover, .woocommerce-tabs ul.tabs li a:hover {
    color: black; }
  /* line 1589, ../scss/layout.scss */
  .woocommerce_tabs ul.tabs li.active, .woocommerce-tabs ul.tabs li.active {
    position: relative; }
  /* line 1593, ../scss/layout.scss */
  .woocommerce_tabs ul.tabs li.active:before, .woocommerce-tabs ul.tabs li.active:before {
    content: "";
    display: block;
    position: absolute;
    top: -.236em;
    left: 0;
    right: 0;
    height: .236em;
    background: black;
    border-top: 1px solid #111111;
    -webkit-border-top-left-radius: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box; }
  /* line 1617, ../scss/layout.scss */
  .woocommerce_tabs ul.tabs li.active a, .woocommerce-tabs ul.tabs li.active a {
    position: relative;
    background: #fff;
    box-shadow: 0 1px 2px 0 #666666;
    -webkit-box-shadow: 0 1px 2px 0 #666666;
    color: black;
    border-top-color: black; }
  /* line 1629, ../scss/layout.scss */
  .woocommerce_tabs ul.tabs li.active a:after, .woocommerce-tabs ul.tabs li.active a:after {
    content: "";
    display: block;
    position: absolute;
    bottom: -4px;
    left: 0;
    right: 0;
    height: 4px;
    background: #fff; }
  /* line 1657, ../scss/layout.scss */
  .woocommerce_tabs .panel, .woocommerce-tabs .panel {
    background: #fff;
    padding: 2.618em;
    border: 1px solid #666666; }
  /* line 1669, ../scss/layout.scss */
  .woocommerce_tabs table.shop_attributes p, .woocommerce-tabs table.shop_attributes p {
    margin-bottom: 0; }
  /* 7.3 Cart */
  /* line 1681, ../scss/layout.scss */
  table.cart {
    margin-bottom: 2.618em; }
  /* line 1685, ../scss/layout.scss */
  table.cart .product-remove, table.cart .product-thumbnail, table.cart .product-price {
    position: static; }
  /* line 1693, ../scss/layout.scss */
  table.cart td.actions {
    text-align: right;
    position: relative;
    padding-bottom: 0; }
  /* line 1699, ../scss/layout.scss */
  table.cart td.actions .coupon {
    width: 65%;
    float: left; }
  /* line 1709, ../scss/layout.scss */
  table.cart td.actions .input-text, table.cart td.actions .button, table.cart td.actions .checkout-button {
    display: inline-block;
    margin-bottom: 0 !important; }
  table.cart td.actions .input-text {
    height: 38px;
    width: 30%;
    text-align: center; }
  /* line 1719, ../scss/layout.scss */
  table.cart td.actions .button {
    margin-left: 10px;
    float: none !important;
    width: auto !important;
    height: 38px;
    padding: 0 20px;
    top: 0; }
  .cart-collaterals {
    margin-bottom: 120px; }
  /* line 41, ../scss/mixins.scss */
  .cart-collaterals:after {
    content: "\0020";
    display: block;
    height: 0;
    overflow: hidden;
    clear: both; }
  /* line 1737, ../scss/layout.scss */
  .cart-collaterals .cross-sells, .cart-collaterals .cart_totals, .cart-collaterals .shipping_calculator {
    width: 48%;
    float: left; }
  th {
    font-weight: normal !important; }
  .wc-proceed-to-checkout .checkout-button.button.alt.wc-forward {
    float: right; }
  .wc-continue-shopping .button.continue-button {
    float: right;
    margin-top: 10px;
    border: 1px solid black;
    color: black;
    background-color: white; }
  .select2-container .select2-choice {
    border-color: black !important;
    height: 27px;
    line-height: 27px; }
  #order_review td.product-total,
  #order_review th.product-total {
    text-align: right; }
  .shipping-calculator-form #calc_shipping_country {
    width: 50%;
    margin-top: 10px; }
  .shipping-calculator-form .button {
    float: right;
    margin-bottom: 20px;
    width: 130px !important;
    padding: 0 15px;
    height: 24px;
    line-height: 17px; }
  /* line 1749, ../scss/layout.scss */
  .cart-collaterals .cart_totals {
    float: right;
    width: 400px; }
  /* line 1757, ../scss/layout.scss */
  .cart-collaterals .shipping_calculator {
    float: right;
    width: 247px;
    clear: right; }
  /* line 1769, ../scss/layout.scss */
  .cart-collaterals .cross-sells ul.products li.product {
    width: 48%; }
  /* 7.4 Checkout */
  /* line 1785, ../scss/layout.scss */
  /* line 1791, ../scss/layout.scss */
  .checkout #shiptobilling {
    float: right;
    margin: 0;
    text-align: right;
    width: 48%; }
  /* line 1801, ../scss/layout.scss */
  .checkout #shiptobilling label {
    white-space: nowrap; }
  /* line 1807, ../scss/layout.scss */
  /* 7. IE FIXES */
  /* line 1921, ../scss/layout.scss */
  .ie7 #top-nav {
    position: relative;
    z-index: 9999999; }
  /* line 1929, ../scss/layout.scss */
  .ie7 #header {
    position: relative;
    z-index: 9999999; } }

.term-description {
  display: none; }

#wrapper {
  margin: 0 auto;
  position: relative;
  padding-bottom: 120px;
  box-sizing: border-box;
  min-height: 100vh; }

.frontpage-slideshow {
  width: 100%;
  min-width: 1040px;
  max-width: 1920px;
  position: relative;
  z-index: 1;
  margin: 0 auto; }
  .frontpage-slideshow .slide {
    width: 100%;
    padding-bottom: 70%;
    height: 0;
    overflow: hidden; }
  .frontpage-slideshow img {
    height: 100%;
    width: auto;
    position: absolute;
    top: 0;
    left: 0; }
    .frontpage-slideshow img.center {
      transform: translate(-50%, 0);
      left: 50%; }
    .frontpage-slideshow img.left {
      transform: translate(-100%, 0);
      left: 50%; }

.home .footer {
  border-top: none; }

.footer {
  padding-right: 3px;
  padding-left: 3px;
  padding-top: 30px;
  border-top: 1px solid black;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -460px;
  height: 120px;
  box-sizing: border-box; }

.footer p {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 5px; }

.footer p.contact a {
  text-transform: lowercase;
  margin-bottom: 0; }

.footer p.msg {
  font-weight: 400;
  text-transform: none;
  margin-top: 5px;
  margin-bottom: 0;
  font-weight: 300;
  color: #121212; }

.footer p.contact {
  width: 243px;
  float: left; }

.footer .follow-us {
  width: 100px;
  position: absolute;
  top: 30px;
  right: 0; }

.footer .follow-us p {
  text-align: right; }

.footer .newsletter-form {
  width: auto;
  float: left; }

.footer input[type=text],
.footer input[type=email] {
  width: 167px;
  height: 22px; }

.footer input[type=submit] {
  display: block;
  float: right;
  margin-top: 0;
  margin-left: 5px;
  height: 24px; }

.footer .follow-us-nav li {
  float: right; }

.footer .follow-us-nav li.facebook {
  margin-right: -3px; }

.footer .follow-us-nav li a {
  display: block;
  width: 20px;
  height: 20px;
  cursor: pointer; }

.page-template-page-stores-php .column {
  float: left;
  width: 25%; }

.page-template-page-stores-php .two-column {
  float: left;
  width: 50%; }

.page-template-page-stores-php .three-column {
  float: left;
  width: 75%; }

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0; }

.clearfix {
  display: inline-block; }

/* start commented backslash hack \*/
* html .clearfix {
  height: 1%; }

.clearfix {
  display: block; }

/* close commented backslash hack */
.page-template-page-stores-php .column.us h1,
.page-template-page-stores-php .column.us h2,
.page-template-page-stores-php .column.us p,
.page-template-page-stores-php .column.netherlands h1,
.page-template-page-stores-php .column.netherlands h2,
.page-template-page-stores-php .column.netherlands p {
  padding-left: 55px; }

.page-template-page-stores-php .column.second {
  margin-top: 46px; }

.page-template-page-stores-php .country h1 {
  margin: 0 0 1em 0;
  padding: 0;
  font-size: 1.16666666666em;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 18px; }

.page-template-page-stores-php .country h1.continent {
  margin: 0 0 28px 0; }

.page-template-page-stores-php h2.store {
  margin: 0 0 1em 0;
  padding: 0;
  font-size: 1.16666666666em;
  text-transform: uppercase;
  font-weight: 300;
  line-height: 18px; }

.page-template-page-stores-php .page p {
  font-size: 1em;
  color: #121212;
  font-weight: 300;
  margin-bottom: 28px;
  line-height: 18px; }

.page-template-page-stores-php p:last-child {
  margin-bottom: 42px; }

.lookbook-pictures {
  list-style: none;
  margin: 0 -11px; }

.lookbook-pictures li {
  float: left;
  width: 292px;
  height: 292px;
  margin: 0 11px 22px 11px;
  background: white;
  overflow: hidden;
  display: block; }

.lookbook-pictures li.transparent {
  background: transparent; }

.lookbook-pictures li a {
  display: block;
  width: 100%;
  height: 100%;
  position: relative; }

.magnify_hover {
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  top: 0;
  left: 0;
  background: #fafafa;
  opacity: 0;
  -webkit-transition: opacity ease-in-out 0.2s;
  -moz-transition: opacity ease-in-out 0.2s;
  -ms-transition: opacity ease-in-out 0.2s;
  -o-transition: opacity ease-in-out 0.2s;
  transition: opacity ease-in-out 0.2s; }

.lookbook-pictures li a:before {
  display: block;
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
  z-index: 200;
  content: " ";
  background-image: url("../images/qov_sprite.svg");
  background-position: -429px -319px;
  background-repeat: no-repeat;
  opacity: 0;
  -webkit-transition: opacity ease-in-out 0.2s;
  -moz-transition: opacity ease-in-out 0.2s;
  -ms-transition: opacity ease-in-out 0.2s;
  -o-transition: opacity ease-in-out 0.2s;
  transition: opacity ease-in-out 0.2s; }

.lookbook-pictures li a:hover:before {
  opacity: 1; }

.page-template-page-story-php h1 {
  margin: 30px 0; }

.lookbook-pictures li a:hover .magnify_hover {
  opacity: 0.66; }

.credits {
  padding: 30px 0; }
  .credits.border {
    margin-bottom: 60px;
    border-bottom: 1px solid black; }
  .credits p {
    text-align: right; }

img.peace {
  float: none;
  margin: 0 auto;
  width: 24px;
  height: auto;
  background: transparent;
  margin-top: 10px; }

.lookbook-pictures li img {
  max-height: 100%;
  max-width: 100%; }

.lookbook-pictures li.portrait img {
  margin: 0 auto; }

.lookbook-pictures li.landscape span.table {
  display: table;
  width: 100%;
  height: 100%; }

.lookbook-pictures li.landscape span.table-cell {
  display: table-cell;
  vertical-align: middle;
  position: relative; }

/*# sourceMappingURL=style.css.map */